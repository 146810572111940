import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/context';
import { Error } from '../../../services/models/error-models';
import { arteiaLogoSrc } from '../../generic';
import { componentStyle as cs } from './styles';

export function PrivateLogin() {
	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);

	const context = useContext(AppContext);

	let history = useHistory();

	const albumUuid = new URLSearchParams(history.location.search).get('uuid');

	if (!albumUuid) {
		console.warn('Missing album uuid');

		history.push('/');

		return null;
	}

	function handleSubmit(password: string) {
		// NOTE: Legacy flow is based on decrypting album in order to establish if password is valid.
		context.privateAlbumService.setPassword(password);

		context.privateAlbumService
			.getAlbumObs(albumUuid as string, false)
			.subscribe(
				(album) => {
					history.goBack();
				},
				(error: Error) => {
					context.privateAlbumService.setPassword('');

					if (error === +Error.PrivateAlbumNotFound) {
						history.push('/private/expired');
					} else if (error === +Error.UnableToDecryptPrivateAlbum) {
						setPasswordError(true);
					}
				}
			);
	}

	return (
		<div style={cs.wrapper}>
			<img src={arteiaLogoSrc} />

			<div style={cs.infoBox}>
				<div style={cs.infoText}>
					Enter password to access the private showroom
				</div>

				<div style={cs.inputLabel}>Password</div>

				<div style={cs.inputWrapper}>
					<div style={{ marginBottom: '1rem' }}>
						<input
							style={cs.input}
							type="password"
							value={password}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit(password);
								}
							}}
							onChange={(event) => {
								setPassword(event.target.value);
							}}
						/>
						{passwordError ? (
							<span style={cs.error}>Password error</span>
						) : null}
					</div>

					<div style={cs.submitButton} onClick={() => handleSubmit(password)}>
						<span style={{ marginRight: '0.5rem' }}>SUBMIT</span>{' '}
						<img src="/icons/arrow.svg" />
					</div>
				</div>
			</div>
		</div>
	);
}
