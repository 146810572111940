import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { AppContext } from '../../context/context';
import { AppContext as AppContextModel } from '../../context/models';
import { ViewState, ViewType } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { Error } from '../../services/models/error-models';
import {
	albumContainsArtworks,
	getSanitizedAlbum,
	getViewType,
} from '../../utils';
import { artworksLoadNumber } from '../generic';
import { State } from './models';
import { getView } from './utils';

type Props = RouteComponentProps<{}>;

export class HomeBase extends React.Component<Props, State> {
	static contextType = AppContext;

	constructor(props: any) {
		super(props);

		const viewType = getViewType(props.location);

		this.state = {
			viewState: ViewState.Loading,
			viewType,
			album: null,
			// NOTE: Used to detect any viewport changes (viewportType is no enough for manual window resizes)
			dimensions: null,
		};
	}

	componentSubs: Subscription = new Subscription();

	privateAlbumAvailabilityChecked = false;

	// NOTE: Both private/public getAlbumObs calls have the same input and return types.
	setAlbum = (isPrivate: boolean, preflight: boolean) => {
		const albumUuid = this.getAlbumUuid(this.state.viewType);

		if (!albumUuid) {
			console.warn('Unable to get album UUID');

			this.setState({
				viewState: ViewState.Error,
			});

			return;
		}

		const getAlbumObservable = isPrivate
			? (this.context as AppContextModel).privateAlbumService.getAlbumObs(
					albumUuid,
					true
			  )
			: (this.context as AppContextModel).publicAlbumService.getAlbumObs(
					albumUuid
			  );

		const getAlbumArtworksObservable = isPrivate
			? (this.context as AppContextModel).privateAlbumService
					.getAlbumArtworksObs
			: (this.context as AppContextModel).publicAlbumService
					.getAlbumArtworksObs;

		this.componentSubs.add(
			getAlbumObservable.subscribe(
				(album: Album<Artwork>) => {
					if ((!isPrivate && !preflight) || (isPrivate && !preflight)) {
						// NOTE: Detect initial album load and update it with initial artwork load before updating state
						if (!albumContainsArtworks(album)) {
							this.componentSubs.add(
								getAlbumArtworksObservable(
									albumUuid,
									artworksLoadNumber,
									0,
									null
								).subscribe((album) => {
									this.setState({
										viewState: ViewState.Ok,
										// NOTE: Home does not care about undefined artworks
										album: getSanitizedAlbum(album),
									});
								})
							);
						} else {
							this.setState({
								viewState: ViewState.Ok,
								album: getSanitizedAlbum(album),
							});
						}
					} else {
						this.privateAlbumAvailabilityChecked = true;

						this.updateComponent();
					}
				},
				(error: Error) => {
					if (!preflight) {
						console.warn(error, albumUuid);

						this.setState({ viewState: ViewState.Error });

						return;
					}

					if (preflight && error === +Error.PrivateAlbumNotFound) {
						this.props.history.push(`/private/expired`);
					} else {
						this.privateAlbumAvailabilityChecked = true;

						this.updateComponent();
					}
				}
			)
		);
	};

	// TODO: Update to accomodate public/private load more as per set album.
	loadMoreArtworks = () => {
		const isPrivate =
			this.context.privateAlbumService.getPassword() &&
			this.state.viewType === +ViewType.Private;

		const observable = isPrivate
			? (this.context as AppContextModel).privateAlbumService
					.getAlbumArtworksObs
			: (this.context as AppContextModel).publicAlbumService
					.getAlbumArtworksObs;

		this.componentSubs.add(
			observable(this.state.album!.uuid, artworksLoadNumber, 0, null).subscribe(
				(album) => {
					this.setState({ album: getSanitizedAlbum(album) });
				},
				() => {
					console.warn('Unable to load more artworks');
				}
			)
		);
	};

	// NOTE: User lands on this view using URL. URL as SSoT.
	getAlbumUuid = (viewType: ViewType): string | null => {
		let albumUuid: string | null = null;

		if (viewType === +ViewType.Private) {
			// NOTE: Private album path (this is legacy and maintained because of no backedn updates [this is how collect creates private album link]).
			//		 We need to extract album UUID from pathname.
			albumUuid = this.props.location.pathname.split('/')[2];
		} else if (viewType === +ViewType.Public) {
			const urlParams = new URLSearchParams(this.props.location.search);

			albumUuid = urlParams.get('uuid');
		} else {
			console.warn('Unknown viewType');
		}

		// FIX: Update logic to check for valid UUID aka GUID instead of just checking for empty string.
		if (!albumUuid) {
			console.warn('Unable to extract album UUID');

			this.setState({
				viewState: ViewState.Error,
			});

			return null;
		}

		return albumUuid;
	};

	updateComponent = () => {
		const password = this.context.privateAlbumService.getPassword();

		// NOTE: Preflight to check if private album exists. Done due to legacy logic. The only way to establish (redirect user to expired artwork) that album expired is to get album
		//       data and act on onerror.
		if (
			!this.privateAlbumAvailabilityChecked &&
			this.state.viewType === +ViewType.Private
		) {
			this.setAlbum(true, true);

			return;
		}

		// NOTE: Redirect to private login page if password is not found
		if (!password && this.state.viewType === +ViewType.Private) {
			this.props.history.push(
				`/private/login?uuid=${this.getAlbumUuid(this.state.viewType)}`
			);

			return;
		}

		// NOTE: Either public or private album with password
		if (this.state.viewType === +ViewType.Public) {
			this.setAlbum(false, false);
		} else if (this.state.viewType === +ViewType.Private && password) {
			this.setAlbum(true, false);
		} else {
			this.setState({
				viewState: ViewState.Error,
			});
		}
	};

	componentDidMount() {
		this.updateComponent();
	}

	componentWillUnmount() {
		this.componentSubs.unsubscribe();
	}

	render() {
		return getView.call(this);
	}
}

export const Home = withRouter(HomeBase);
