import React, { SyntheticEvent } from 'react';
import { Measurement } from '../../../../ads-components/boxes/static-image-box/models';
import { RowHorizontalContainer } from '../../../../ads-components/containers/grids/rows/RowHorizontalContainer';
import { AppContext } from '../../../../context/context';
import { Props, State } from '../../models';
import { getInitialState, updateDetailsState } from '../../utils';
import { ArtworkDetails } from '../generic/artwork-details/ArtworkDetails';
import { ArtworkMeasurements } from '../generic/artwork-measurements/ArtworkMeasurements';
import { NavigationBar } from '../generic/navigation-bar/NavigationBar';
import { componentStyle as cs } from './styles';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { withRouter } from 'react-router-dom';
import { actionStyle } from '../../../top-bar/styles';

const previewImagesRowHeight = '10vh';
type PreviewType = 'default' | 'selected' | 'hovered';

const tabs = [
	{
		title: 'Provenance',
		content: 'first description',
		id: 0,
	},
	{
		title: 'Literature',
		content: 'second description',
		id: 1,
	},
	{
		title: 'Exhibition',
		content: 'second description',
		id: 2,
	},
	{
		title: 'Description',
		content: 'second description',
		id: 3,
	},
];

class DetailsLargeLandscapeBase extends React.Component<Props, State> {
	static contextType = AppContext;

	wrapperRef = React.createRef<HTMLDivElement>();

	onKeyDown = (event: SyntheticEvent<any, KeyboardEvent>) => {
		const keyCode = event.nativeEvent.keyCode;

		if (keyCode === 39) {
			this.props.changeArtworkFn('next', false, 0);
		} else if (keyCode === 37) {
			this.props.changeArtworkFn('previous', false, 0);
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = getInitialState();
	}

	componentDidMount() {
		// NOTE: Used to enable keypress toggling after view loads
		this.wrapperRef.current!.focus();

		updateDetailsState.call(
			this,
			cs,
			previewImagesRowHeight,
			Measurement.Height
		);
	}

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.previewIndex !== this.props.previewIndex ||
			prevProps.artwork._id !== this.props.artwork._id
		) {
			updateDetailsState.call(
				this,
				cs,
				previewImagesRowHeight,
				Measurement.Height
			);
		}
	}

	render() {
		return (
			<>
				<div
					ref={this.wrapperRef}
					style={cs.wrapper}
					onKeyDown={(e) => this.onKeyDown(e)}
					tabIndex={0}>
					<div style={cs.navigationBar}>
						<NavigationBar
							albumArtworkCount={this.props.albumArtworkCount}
							artworkIndex={this.props.artwork.artworkOffset}
							changeArtwork={this.props.changeArtworkFn}
						/>
					</div>

					<div style={cs.mainContainer}>
						<div style={cs.leftContainer}>
							<div
								style={{
									...cs.imageContainer,
									padding: this.state.previews
										? cs.imageContainer.padding
										: '5vh',
								}}>
								{this.state.preview}
							</div>
							{this.state.previews ? (
								<div style={{ height: previewImagesRowHeight }}>
									<RowHorizontalContainer
										numberOfRows={1}
										rowHeight={previewImagesRowHeight}>
										{this.state.previews}
									</RowHorizontalContainer>
								</div>
							) : null}
						</div>

						<div style={cs.rightContainer}>
							<ArtworkDetails
								authorName={this.props.artwork.authors[0].name.full}
								artworkTitle={this.props.artwork.titles[0].value}
								creation={this.props.artwork.creation}
							/>

							<ArtworkMeasurements artwork={this.props.artwork} />

							{/* <div style={descriptionContainerStyle}>
					<MaxTextLinesBox
						showReadMoreIndicator={true}
						readMoreClick={() => alert('read more click')}
						maxLinesCount={40}>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even slightly
						believable.
					</MaxTextLinesBox>
				</div> */}

							{/* <div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '4vh',
						}}>
						<span
							style={{
								color: 'gray',
								marginRight: '0.5vw',
								fontSize: '1.2rem',
							}}>
							Price:{' '}
						</span>
						<span style={{ fontSize: '1.2rem' }}>--- EUR</span>
					</div> */}

							{/* <div id="contact-details">
					<div>CONTACT DETAILS</div>
					<div>Avatar: dads@arteia.com</div>
				</div>

				<div id="files">
					<div>FILES</div>
					<div>...</div>
				</div> */}

							{/* <div style={tabbedBoxContainerStyle}>
					<TabbedTextBox tabs={tabs} defaultTabId={0}></TabbedTextBox>
				</div> */}
						</div>
					</div>
				</div>
				<ViewActionsPortal>
					<div
						onClick={() =>
							this.props.history.push(
								this.props.private
									? `/private/${this.props.artwork.albumUuid}/artworks`
									: `/album/?uuid=${this.props.artwork.albumUuid}`
							)
						}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-arrow-left-big.svg"
						/>
						<span style={actionStyle.text}>{this.props.albumName}</span>
					</div>
				</ViewActionsPortal>
			</>
		);
	}
}

export const DetailsLargeLandscape = withRouter(DetailsLargeLandscapeBase);
