import {
	AlignItems,
	BoxSizing,
	Display,
	FlexDirection,
	FontWeight,
	JustifyContent,
} from '../../../../../ads-components/models';
import {
	defaultText,
	defaultTitle,
	defaultVerticalMargin,
} from '../../../../../styles';
import { ComponentStyle as ComponentStyleModel } from '../models';

const activeTabColor = '#0089D4';

export class ComponentStyle implements ComponentStyleModel {
	container = {
		display: Display.Flex,
		height: '100%',
		overflow: 'hidden',
		flexDirection: FlexDirection.Column,
	};

	tabsContainer = {
		display: Display.Flex,
		boxShadow: 'rgba(0,0,0,0.5) 0 3px 4px -2px',
	};

	tab = (active: boolean) => new TabStyle(active);

	header: any;

	detailsContent = {
		padding: '1rem',
		overflow: 'auto',
	};

	contentContainer = {
		overflow: 'auto',
		height: '100%',
		boxSizing: BoxSizing.BorderBox,
	};

	title = {
		...defaultTitle,
		fontSize: '1.8rem',
		margin: '1vh 0',
	};

	description = {
		marginBottom: defaultVerticalMargin,
		...defaultText,
	};

	iconStatusBarContainer = {
		display: Display.Flex,
		justifyContent: JustifyContent.FlexEnd,
	};

	artworkList = {
		padding: '1rem',
	};

	constructor(headerContainerHeight: string) {
		this.header = new Header(headerContainerHeight);
	}
}

class Header {
	height: string;
	width = '100%';

	constructor(height: string) {
		this.height = height;
	}
}

export class TabStyle {
	display = Display.Flex;
	alignItems = AlignItems.Center;
	justifyContent = JustifyContent.SpaceAround;
	boxSizing = BoxSizing.BorderBox;
	flex = '1 1 auto';
	padding = '0.5rem';
	cursor = 'pointer';
	color = activeTabColor;
	font = FontWeight.Bolder;
	borderBottom: string;

	constructor(active: boolean) {
		this.borderBottom = active ? `2px solid ${activeTabColor}` : 'none';
		this.color = active ? activeTabColor : 'gray';
	}
}
