import { forkJoin, from, Observable, Subscription, throwError, of } from 'rxjs';
import { AbstractPublicAlbumService } from './models/abstracts';
import { Album, ApiError, Artwork } from './models/api-models';
import { handleApiError } from './utils/handle-api-error';
import { Error as ErrorModel } from './models/error-models';
import { getArtworkRequestObservables } from './utils';

// NOTE: This service is note intended to hold private album data
export class PublicAlbumService extends AbstractPublicAlbumService {
	constructor() {
		super();
	}

	addAlbumPreviewsToCache(albums: Album<Artwork>[]): void {
		this.albumPreviewsCache = !this.albumPreviewsCache
			? albums
			: [
					...((this.albumPreviewsCache as unknown) as Album<Artwork>[]),
					...albums,
			  ];
	}

	// NOTE: List.tsx calls getAlbumsPreviewsObs as initial effect but when coming back from home we do not load more album previews.
	//	     For that user should use 'load more' explicitly.
	getAlbumPreviewsCache() {
		return this.albumPreviewsCache;
	}

	// NOTE: For List view
	getAlbumPreviewsObs(): Observable<Album<Artwork>[]> {
		return new Observable((subscriber) => {
			const skip: number = this.albumPreviewsCache.length;

			const limit: number = 5;

			const subscription: Subscription = this.apiService
				.getAlbums(skip, limit)
				.subscribe(
					(albums: Album<Artwork>[]) => {
						this.addAlbumPreviewsToCache(albums);

						subscriber.next(
							(this.albumPreviewsCache as unknown) as Album<Artwork>[]
						);
						subscriber.complete();

						subscription.unsubscribe();
					},
					// TODO: Create error model
					(error: any) => {
						subscriber.error(error);
						subscription.unsubscribe();
					}
				);
		});
	}

	// NOTE: On every 'full album' load we should cache it.
	addAlbumToCache(album: Album<Artwork>): void {
		// NOTE: Duplication should not occure because setting only happens when album does not exist in cache
		// NOTE: As SSOC it can be altered to use any persistance mechanism necessary
		// NOTE: Takes care of updating album ex. adding album with updated artworks
		const albumIndex = this.albumsCache.findIndex(
			(cachedAlbum) => cachedAlbum.uuid === album.uuid
		);

		if (albumIndex + 1) {
			this.albumsCache[albumIndex < 0 ? 0 : albumIndex] = album;
		} else {
			this.albumsCache.push(album);
		}
	}

	getAlbumObs = (albumUuid: string): Observable<Album<Artwork>> => {
		// NOTE: Simple caching. Does not take into account scenario when album data is updated during given use (refreshing solves issue).
		return new Observable((subscriber) => {
			const cachedAlbum = this.albumsCache.find(
				(album) => album.uuid === albumUuid
			);

			if (!cachedAlbum) {
				// NOTE: First call api to retreive album data
				const subscription: Subscription = this.apiService
					.getAlbum(albumUuid)
					.subscribe(
						(album: Album<Artwork>) => {
							const albumError = handleApiError(album);

							if (albumError !== null) {
								subscriber.error(albumError);

								subscription.unsubscribe();

								return;
							}

							// NOTE: Update cache. Simply return found album after cache insertion.
							this.addAlbumToCache(album);

							subscriber.next(album);
							subscriber.complete();

							subscription.unsubscribe();
						},
						(error: ErrorModel) => {
							subscriber.error(ErrorModel[error]);
							subscription.unsubscribe();
						}
					);
			} else {
				subscriber.next(cachedAlbum);
				subscriber.complete();
			}
		});
	};

	// NOTE: Used to extract artwork details
	// NOTE: Observabe types mimics Details.tsx data handling
	getArtworkObs(
		albumUuid: string,
		artworkId: string
	): Observable<[Album<Artwork>, Artwork]> {
		return new Observable((subscriber) => {
			let album: Album<Artwork> | undefined;
			let artwork: Artwork | undefined;

			// NOTE: Check cache first to see if we have albumCached
			album = this.albumsCache.find((album) => album.uuid === albumUuid);

			if (album) {
				// NOTE: Check cache for artwork. Remember that cache may contain undefined artworks
				artwork = album.artworks.find(
					(artwork) => artwork && artwork._id === artworkId
				);
			}

			// NOTE: We are safe to extract data from albumsCache
			if (album && artwork) {
				subscriber.next([album, artwork]);
				subscriber.complete();

				return;
			}

			// NOTE: Cover initial load scenario aka user uses artwork link to load application.
			// 		 At this point either album or artwork are loaded
			const subscription = from(
				forkJoin([
					this.getAlbumObs(albumUuid),
					this.apiService.getArtwork(albumUuid, artworkId),
				])
			).subscribe(
				(data: any[]) => {
					const albumError = handleApiError(data[0]);
					const artworkError = handleApiError(data[1]);

					if (albumError !== null || artworkError !== null) {
						subscriber.error([
							handleApiError(data[0]),
							handleApiError(data[1]),
						]);

						subscription.unsubscribe();

						return;
					}

					const album = data[0] as Album<Artwork>;
					const artwork = data[1] as Artwork;

					// NOTE: It's required to put artwork into correct slot
					album.artworks[artwork.artworkOffset] = artwork;

					this.addAlbumToCache(album);

					subscriber.next([album, artwork]);
					subscriber.complete();

					subscription.unsubscribe();
				},
				(error: any) => {
					console.warn(
						'API error on getting artworks details',
						albumUuid,
						artworkId
					);
					subscriber.error();
				}
			);
		});
	}

	getAlbumArtworksObs = (
		albumUuid: string,
		limit: number,
		// NOTE: (1) When changing artworks on details we want to load next artwork from specific index only.
		initialSkip: number = 0,
		// NOTE: (2) When changing artworks on detaisl we want to load previous artwork till current only.
		topSentinelIndex: number | null
	): Observable<Album<Artwork | undefined>> => {
		// NOTE: This logic will never gets called without initial album load and cache population
		let cachedAlbum = this.albumsCache.find(
			(album) => album.uuid === albumUuid
		);

		if (!cachedAlbum) {
			console.warn('Album does not exist');

			return throwError('Album does not exist');
		}

		cachedAlbum = cachedAlbum as Album<Artwork>;

		const apiCalls = getArtworkRequestObservables(
			albumUuid,
			cachedAlbum.artworks,
			initialSkip,
			limit,
			this.apiService.getAlbumArtworks,
			topSentinelIndex
		);

		return new Observable((subscriber) => {
			const subscription = forkJoin(apiCalls).subscribe(
				(result: any) => {
					result = result as (Artwork[] | ApiError)[];

					result.filter((element: Artwork[] | ApiError) => {
						const error = handleApiError(element);

						if (error !== null) {
							console.warn(ErrorModel[error]);

							return false;
						}

						return true;
					});

					// NOTE: At this point it's a logic error if incoming artworks are already existing
					// NOTE: Insert into undefined slots using artworkOffset
					((<unknown>result) as Artwork[][]).forEach((result: Artwork[]) => {
						result.forEach((artwork) => {
							cachedAlbum!.artworks[artwork.artworkOffset] = artwork;
						});
					});

					this.addAlbumToCache(cachedAlbum!);

					subscriber.next(cachedAlbum);
					subscriber.complete();

					subscription.unsubscribe();
				},
				(error: ApiError) => {
					subscriber.error(handleApiError(error));
				}
			);
		});
	};

	likeAlbum = (albumUuid: string): Observable<boolean> =>
		this.apiService.likeAlbum(albumUuid);
}
