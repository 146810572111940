import { Month } from './models';

export function getExpirationDate(inputDate: string): string {
	// NOTE: Api indicates that album does not expires
	if (inputDate.toLocaleLowerCase() === 'never') {
		return '';
	}

	if (isNaN(Date.parse(inputDate))) {
		console.warn('Unable to parse album expiration date');

		return '';
	}

	const date = new Date(inputDate as string);

	const dateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

	return `Expires on ${new Date(dateUTC).getDate()} ${
		Month[new Date(dateUTC).getMonth()]
	} ${new Date(dateUTC).getFullYear()}`;
}
