import React from 'react';
import { getPostedDate } from '../../../utils';

interface Props {
	postedOn: string | null;
}

export function PostIndicator(props: Props) {
	const indicator = props.postedOn ? (
		<div style={{ fontSize: '1.3rem', color: '#555657' }}>
			<span>{getPostedDate(props.postedOn as string)}</span>
		</div>
	) : null;

	return indicator;
}
