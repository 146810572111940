import { HomeBase } from '../Home';
import { ViewState, ViewType } from '../../../models';
import { LogoIndicator } from '../../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import React from 'react';
import { defaultLogoIndicatorSize } from '../../../styles';
import { Effect } from '../../../ads-components/indicators/logo-loading-indicator/models';
import { getAlbumView } from '.';

export function getView(this: HomeBase) {
	if (
		this.state.viewState === ViewState.Loading ||
		this.state.viewState === ViewState.Error
	) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					flex: '1',
				}}>
				<LogoIndicator
					width={defaultLogoIndicatorSize}
					effect={
						this.state.viewState === ViewState.Loading
							? Effect.Pulse
							: Effect.Static
					}>
					{this.state.viewState}
				</LogoIndicator>
			</div>
		);
	} else if (this.state.viewState === ViewState.Ok) {
		return getAlbumView(
			this.context.viewportType,
			this.state.album,
			this.loadMoreArtworks,
			this.state.viewType === +ViewType.Private ? true : false
		);
	} else {
		return <h1>Unknown view state</h1>;
	}
}
