import React from 'react';
import { Measurement } from '../../../../ads-components/boxes/static-image-box/models';
import { RowHorizontalContainer } from '../../../../ads-components/containers/grids/rows/RowHorizontalContainer';
import { AppContext } from '../../../../context/context';
import { Props, State } from '../../models';
import {
	getInitialState,
	updateDetailsState,
	cropAlbumName,
} from '../../utils/';
import { ArtworkDetails } from '../generic/artwork-details/ArtworkDetails';
import { ArtworkMeasurements } from '../generic/artwork-measurements/ArtworkMeasurements';
import { NavigationBar } from '../generic/navigation-bar/NavigationBar';
import { componentStyle as cs } from './styles';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { actionStyle } from '../../../top-bar/styles';

export class DetailsSmallPortrait extends React.Component<Props, State> {
	// NOTE: How to establish context model in ts?
	static contextType = AppContext;
	currentContext: string | null = null;

	constructor(props: Props) {
		super(props);

		this.state = getInitialState();
	}

	componentDidMount() {
		this.currentContext = JSON.stringify(this.context);

		updateDetailsState.call(this, cs, cs.previewList.height, Measurement.Width);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			prevProps.previewIndex !== this.props.previewIndex ||
			prevProps.artwork._id !== this.props.artwork._id
		) {
			updateDetailsState.call(
				this,
				cs,
				cs.previewList.height,
				Measurement.Width
			);
		}
	}

	render() {
		return (
			<div style={cs.wrapper} id="wrapper">
				<div style={cs.navigationBar}>
					<NavigationBar
						albumArtworkCount={this.props.albumArtworkCount}
						artworkIndex={this.props.artwork.artworkOffset}
						changeArtwork={this.props.changeArtworkFn}
					/>
				</div>

				<div style={cs.preview} id="preview">
					<div style={cs.image} id="image">
						{this.state.preview}
					</div>
					<div id="previews-container" style={cs.previewList}>
						<RowHorizontalContainer
							numberOfRows={1}
							rowHeight={cs.previewList.height}>
							{this.state.previews}
						</RowHorizontalContainer>
					</div>
				</div>

				<div id="content" style={cs.content}>
					<ArtworkDetails
						authorName={this.props.artwork.authors[0].name.full}
						artworkTitle={this.props.artwork.titles[0].value}
						creation={this.props.artwork.creation}
					/>

					<ArtworkMeasurements artwork={this.props.artwork} />
				</div>

				<ViewActionsPortal>
					<div
						onClick={() =>
							this.props.history.push(
								`/album/?uuid=${this.props.artwork.albumUuid}`
							)
						}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-arrow-left-big.svg"
						/>
						<span style={{ fontSize: 'smaller' }}>
							{cropAlbumName(this.props.albumName, 23)}
						</span>
					</div>
				</ViewActionsPortal>
			</div>
		);
	}
}
