const api_endpoint = 'https://public-albums.dev.arteia.xyz';
//const api_endpoint = 'http://localhost:3001/presentation-app';
const like_api_endpoint = `${api_endpoint}/like`;

//const api_assets_endpoint = `${api_endpoint}/assets`
const api_assets_endpoint = `https://public-albums.dev.arteia.xyz/assets`;

const signup_endpoint =
	'https://secure.arteia.com/sign-up?returnTo=public-albums-client&userLogin=true';
const login_endpoint =
	'https://secure.arteia.com/login?returnTo=public-albums-client&userLogin=true';

const profile_endpoint = `${api_endpoint}/profile`;

export {
	api_endpoint,
	like_api_endpoint,
	api_assets_endpoint,
	signup_endpoint,
	login_endpoint,
	profile_endpoint,
};
