import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	MutableRefObject,
} from 'react';
import { Subscription } from 'rxjs';
import { SquareImageBox } from '../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { AppContext } from '../../../../context/context';
import { ApiError } from '../../../../services/models/api-models';
import { decryptImage } from '../../../private/utlls';
import { missingArtworkIndicatorUrl } from '../../defaults';
import { Props } from './models';
import { ApiService } from '../../../../services/api.service';

// NOTE: Keep in mind that props.imageSrc in case of an encrypted image is not valid image source.
//		 It should be treated as assetId that returns an object with data property that gets decrypted into proper imageSrc.
export function EncryptedSquareImageBox(props: Props) {
	const componentSub: MutableRefObject<Subscription | null> = useRef(null);
	const id = useRef(Math.random());

	const [imageElement, setImageElement] = useState<JSX.Element>(
		<SquareImageBox
			// NOTE: Used as loading placeholder that will be replaced by fully loaded image after decryption
			//       Reusing same element (aka with same id) will 'stuck' component on loading state.
			key={id.current + 1}
			imageSrc="https://via.placeholder.com/600?text=Loading+image"
			boxSideSize={props.boxSideSize}
		/>
	);

	useEffect(() => {
		if (!props.imageSrc) {
			setImageElement(
				<SquareImageBox
					key={id.current}
					imageSrc={missingArtworkIndicatorUrl}
					boxSideSize={props.boxSideSize}
				/>
			);
		} else {
			componentSub.current = new ApiService()
				.getEncryptedAssetUrlById(props.imageSrc, 'small')
				.subscribe(
					(data: string | ApiError) => {
						const imageSrc = decryptImage(data as string, props.password);

						if (!imageSrc) {
							console.warn('Unable to decrypt image');
						} else {
							setImageElement(
								<SquareImageBox
									key={id.current}
									imageSrc={imageSrc}
									boxSideSize={props.boxSideSize}
								/>
							);
						}
					},
					() => {
						console.warn('Image decryption error', props.imageSrc);
					}
				);
		}

		return () => {
			componentSub.current && componentSub.current.unsubscribe();
		};
	}, [props.imageSrc]);

	return imageElement;
}
