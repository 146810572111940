import { Observable, from } from 'rxjs';
import {
	Album,
	Artwork,
	ApiError,
	EncryptedAlbum,
	EncryptedArtwork,
} from './models/api-models';
import { like_api_endpoint, api_endpoint } from '../env';
import { artworksLoadNumber } from '../components/generic';
import { Error as ErrorModel } from './models/error-models';
import { handleApiError } from './utils/handle-api-error';
import { cookieService } from '../services/cookie.service';

// @ts-ignore:next-line
const allSettled = !Promise.allSettled
	? require('promise.allsettled')
	: // @ts-ignore:next-line
	  Promise.allSettled;

interface LikeApi {
	isLiked: boolean;
	numOfLikes: number;
}

function fetchLikesPromise(album: Album<Artwork>): Promise<Album<Artwork>> {
	return fetch(`${like_api_endpoint}/${album.uuid}`)
		.then((response) => response.json())
		.then((data: LikeApi) => ({ ...album, likes: data.numOfLikes }))
		.catch(() => ({ ...album, likes: null }));
}

function fetchPublicAlbumArtworks(
	albumUuid: string,
	skip: number,
	limit: number
) {
	return fetch(
		`${api_endpoint}/${albumUuid}/artworks/?skip=${skip}&limit=${limit}`
	)
		.then((response) => response.json())
		.then((artworks: Artwork[]) => artworks)
		.catch((error: ApiError) => error);
}

function fetchPrivateAlbumArtworks(
	albumUuid: string,
	skip: number,
	limit: number
) {
	return fetch(
		`${api_endpoint}/private/${albumUuid}/artworks/?skip=${skip}&limit=${limit}`
	)
		.then((response) => response.json())
		.then((artworks: EncryptedArtwork[]) => artworks)
		.catch((error: ApiError) => error);
}

function fetchPrivateAlbum(albumUuid: string) {
	return fetch(`${api_endpoint}/private/${albumUuid}`)
		.then((response) => response.json())
		.then((data: EncryptedAlbum | ApiError) => data)
		.catch((error) => null);
}

function fetchProfile() {
	return fetch(`${api_endpoint}/profile`)
		.then((profile) => ({}))
		.catch((error) => ({}));
}

function fetchAlbumLike(albumUuid: string): Promise<boolean> {
	const headers: Headers = new Headers();

	headers.set('X-AUTH-TOKEN', cookieService.getToken() || '');
	headers.set('accept', 'application-json');

	return fetch(`${api_endpoint}/like/${albumUuid}`, {
		method: 'POST',
		headers,
	})
		.then((response: Response) => {
			if (response.status === 403 || response.status === 401) {
				return false;
			}

			return true;
		})
		.catch((error) => {
			return false;
		});
}

export class ApiService {
	getProfile() {
		return from(fetchProfile());
	}

	// NOTE: This call does not return album artworks!
	getAlbums(
		skip: number = 0,
		limit: number = 40
	): Observable<Album<Artwork>[]> {
		return new Observable((subscriber) => {
			fetch(`${api_endpoint}/latest?skip=${skip}&limit=${limit}`)
				.then((response) => response.json())
				.then((albums: Album<Artwork>[]) => {
					const likePromises: Promise<Album<Artwork>>[] = albums.map((album) =>
						fetchLikesPromise(album)
					);

					// @ts-ignore:next-line
					allSettled
						.call(Promise, likePromises)
						.then((results: any) => {
							subscriber.next(
								results.map((result: any) =>
									result.status === 'fulfilled' ? result.value : result.reason
								)
							);

							subscriber.complete();
						})
						.catch((error: any) => {
							subscriber.error(error);
						});
				})
				.catch((error) => {
					subscriber.error(error);
				});
		});
	}

	getAlbum(albumUuid: string): Observable<Album<Artwork>> {
		return new Observable((subscriber) => {
			fetch(`${api_endpoint}/${albumUuid}`)
				.then((response) => response.json())
				.then((album: Album<Artwork> | ApiError) => {
					const apiError = handleApiError(album);

					if (apiError !== null) {
						subscriber.error(apiError);

						return;
					}

					//@ts-ignore:next-line
					fetchLikesPromise(album as Album<Artwork>)
						// NOTE: At this point album is populated with likes
						.then((album: Album<Artwork> | ApiError) => {
							const apiError = handleApiError(album);

							if (apiError !== null) {
								subscriber.error(apiError);

								return;
							}

							album = album as Album<Artwork>;

							// NOTE: This call is used only once and then album should get cached.
							//       Create artworks collection. Missing artworks are null.
							album.artworks = album.artworksCount
								? [...new Array(album.artworksCount)]
								: [];

							subscriber.next(album);

							subscriber.complete();
						})
						.catch(() => subscriber.error());
				})
				.catch((error) => subscriber.error());
		});
	}

	// NOTE: private-service will decrypt
	getPrivateArtwork(artworkId: string): Observable<EncryptedArtwork> {
		return new Observable((subscriber: any) => {
			fetch(`${api_endpoint}/private/artworks/${artworkId}`)
				.then((response: any) => response.json())
				.then((encryptedArtwork: EncryptedArtwork | ApiError) => {
					// TODO: Model error response
					if (
						(encryptedArtwork as ApiError).errorId ||
						((encryptedArtwork as ApiError).message &&
							(encryptedArtwork as ApiError).message.toLowerCase() ===
								'an internal server error occurred')
					) {
						subscriber.error({
							message: (encryptedArtwork as ApiError).message,
							error: (encryptedArtwork as ApiError).errorId
								? (encryptedArtwork as ApiError).errorId
								: 'Unknown error',
						});
					}

					subscriber.next(encryptedArtwork as EncryptedArtwork);
					subscriber.complete();
				})
				.catch(() => {
					console.warn('Getting private artwork API error', artworkId);
					subscriber.error();
				});
		});
	}

	getArtwork(albumUuid: string, artworkId: string): Observable<Artwork> {
		return new Observable((subscriber) => {
			fetch(`${api_endpoint}/${albumUuid}/artworks/${artworkId}`)
				.then((response: any) => response.json())
				.then((artwork: Artwork | ApiError) => {
					// TODO: Model error response
					if (
						(artwork as ApiError).errorId ||
						((artwork as ApiError).message &&
							(artwork as ApiError).message.toLowerCase() ===
								'an internal server error occurred')
					) {
						subscriber.error({
							message: (artwork as ApiError).message,
							error: (artwork as ApiError).errorId
								? (artwork as ApiError).errorId
								: 'Unknown error',
						});
					}

					subscriber.next(artwork as Artwork);
					subscriber.complete();
				})
				.catch(() => subscriber.error());
		});
	}

	// NOTE: Artwork lazy loading on public albums
	getAlbumArtworks(
		albumUuid: string,
		skip: number,
		limit: number
	): Observable<Artwork[] | ApiError> {
		return from(fetchPublicAlbumArtworks(albumUuid, skip, limit));
	}

	// NOTE: Private albums artwork lazy loading
	getPrivateAlbumArtworks(
		albumUuid: string,
		skip: number,
		limit: number
	): Observable<EncryptedArtwork[] | ApiError> {
		return from(fetchPrivateAlbumArtworks(albumUuid, skip, limit));
	}

	getPrivateAlbum(
		albumUuid: string,
		withArtworks: boolean
	): Observable<EncryptedAlbum> {
		return new Observable((subscriber) => {
			// NOTE: Default values for initial load
			const skip: number = 0;
			const limit: number = artworksLoadNumber;

			// NOTE: Private album availability check does not need to get artworks. It simply gets an album and tries to decrypt it to validate availability and corrent password.
			const promises = withArtworks
				? [
						fetchPrivateAlbum(albumUuid),
						fetchPrivateAlbumArtworks(albumUuid, skip, limit),
				  ]
				: [fetchPrivateAlbum(albumUuid)];

			allSettled
				.call(Promise, promises)
				.then((results: any[]) => {
					let album: EncryptedAlbum | null = null;

					if (results[0].status === 'fulfilled') {
						album = results[0].value;
					} else {
						subscriber.error(ErrorModel.UnableToLoadPrivateAlbum);
					}

					if ((results[0].value as ApiError).errorId) {
						console.warn(
							ErrorModel[ErrorModel.PrivateAlbumNotFound],
							albumUuid
						);

						subscriber.error(ErrorModel.PrivateAlbumNotFound);
					}

					if (!results[1] && album) {
						album.artworks = album.artworksCount
							? [...new Array(album.artworksCount)]
							: [];

						subscriber.next({
							...album,
						});

						subscriber.complete();

						return;
					}

					if (results[1].status === 'fulfilled' && album) {
						album.artworks = album.artworksCount
							? [...new Array(album.artworksCount)]
							: [];

						subscriber.next({
							...album,
						});
					} else {
						subscriber.error(ErrorModel.UnableToLoadPrivateArtwork);
					}

					subscriber.complete();
				})
				.catch(() => {
					subscriber.error();
				});
		});
	}

	getEncryptedAssetUrlById(
		assetId: string,
		size: 'small' | 'nano' | 'hd'
	): Observable<string | ApiError> {
		return new Observable((subscriber) => {
			fetch(`${api_endpoint}/private-assets/${assetId}?size=${size}`)
				.then((response) => response.text())
				.then((data: string | ApiError) => {
					// NOTE: We need to see if we get an error response
					try {
						const parsedData = JSON.parse(data as string);

						if ((parsedData as ApiError).errorId) {
							subscriber.error();
							subscriber.complete();
						}
					} catch {
						subscriber.next(data);
						subscriber.complete();
					}
				})
				.catch((error) => {
					console.warn('getEncryptedAssetUrlById', error);
					subscriber.error();
				});
		});
	}

	likeAlbum(albumUuid: string): Observable<boolean> {
		return from(fetchAlbumLike(albumUuid));
	}
}

// getPrivateAlbumArtworks(
// 	albumUuid: string,
// 	skip: number,
// 	limit: number
// ): Observable<EncryptedArtwork[] | ApiError> {
// 	return from(fetchPrivateAlbumArtworks(albumUuid, skip, limit));
// }
