import React from 'react';
import { componentStyle as cs } from './styles';
import { DefaultRoundBorderButton } from '../../../ads-components/buttons/round-border-button/default/DefaultRoundBorderButton';

export function LoadMoreBar(props: { click: Function }) {
	return (
		<div style={cs.wrapper}>
			<DefaultRoundBorderButton
				onClick={() => props.click()}
				text="Load More"
			/>
		</div>
	);
}
