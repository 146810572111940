import React, { useContext, useState } from 'react';
import { IconStatusBar } from '../../../ads-components/bars/status-bars/icon-status/IconStatusBar';
import { Props } from './models';
import { AppContext } from '../../../context/context';

export function IconsStatusBarContainer(props: Props) {
	const context = useContext(AppContext);

	const [likes, setLikes] = useState<number>(
		props.likes === null ? 0 : props.likes
	);

	function like() {
		const subscription = context.publicAlbumService
			.likeAlbum(props.albumUuid)
			.subscribe(
				(data: boolean) => {
					if (data) {
						setLikes(likes + 1);
					}
				},
				() => {
					alert('error');
				}
			);
	}

	return (
		<IconStatusBar
			statuses={[
				{
					click: like,
					iconUrl: '/icons/ico-like.svg',
					description: likes.toString(),
				},
				{
					iconUrl: '/icons/ico-view.svg',
					description: props.timesVisited ? props.timesVisited.toString() : '0',
				},
				{
					iconUrl: '/icons/ico-artworks.svg',
					description: props.artworksCount
						? props.artworksCount.toString()
						: '0',
				},
			]}
		/>
	);
}
