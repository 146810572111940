import { BaseStyle } from '../../styles';
import {
	Cursor,
	Display,
	JustifyContent,
	AlignItems,
} from '../../../../models';

export class ComponentStyle extends BaseStyle {
	wrapper = {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
	};
	image = {
		cursor: Cursor.Pointer,
	};
}
