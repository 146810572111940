import React from 'react';
import { DefaultHorizontalTag } from '../../../../../../ads-components/tags/horizontal/default/DefaultTag';

export function ForSaleTag() {
	return (
		<DefaultHorizontalTag>
			<span style={{ fontSize: '1.3rem', lineHeight: '1.6rem' }}>FOR SALE</span>
		</DefaultHorizontalTag>
	);
}
