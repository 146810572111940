import React from 'react';
import { getExpirationDate } from '../../../utils';
import { componentStyle as cs } from './styles';

interface Props {
	expiresOn: string | null;
}

export function ExpirationIndicator(props: Props) {
	const indicator = props.expiresOn ? (
		<div style={cs.wrapper}>
			<span>{getExpirationDate(props.expiresOn as string)}</span>
		</div>
	) : null;

	return indicator;
}
