import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { LogoIndicator } from '../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { Effect } from '../../ads-components/indicators/logo-loading-indicator/models';
import { AppContext } from '../../context/context';
import {
	AppContext as AppContextModel,
	ViewportType,
} from '../../context/models';
import { ViewState } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { defaultLogoIndicatorSize } from '../../styles';
import { getAlbumViewModel, scrollToPosition } from '../../utils';
import { LoadMoreBar } from '../generic/load-more-bar/LoadMoreBar';
import { ListItemSmallPortrait } from './list-item/views';
import { ListItemLargeLandscape } from './list-item/views/large-landscape/ListItemLargeLandscape';
import { componentStyle as cs } from './styles';

const listWrapperId = 'list-wrapper';

function getView(viewportType: ViewportType, albums: Album<Artwork>[]) {
	if (!albums.length) {
		return (
			<div style={cs.noArtworks}>
				<h3>No albums</h3>
			</div>
		);
	} else if (
		viewportType === +ViewportType.SmallPortrait ||
		viewportType === +ViewportType.MediumPortrait ||
		viewportType === +ViewportType.SmallLandscape ||
		viewportType === +ViewportType.MediumLandscape
	) {
		return albums.map((album) => {
			const props = getAlbumViewModel(album);

			return (
				<ListItemSmallPortrait
					key={props.id}
					{...props}></ListItemSmallPortrait>
			);
		});
	} else {
		return albums.map((album) => {
			const props = getAlbumViewModel(album);

			return (
				<ListItemLargeLandscape
					key={props.id}
					{...props}></ListItemLargeLandscape>
			);
		});
	}
}

export function List() {
	const context: AppContextModel = useContext(AppContext);

	const [albums, setAlbums] = useState<Album<Artwork>[]>([]);
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [loadMore, setLoadMore] = useState<boolean>(true);

	useEffect(() => {
		const albumsFromCache = context.publicAlbumService.getAlbumPreviewsCache();

		if (!albumsFromCache.length) {
			getAlbums();
		} else {
			setAlbums(albumsFromCache);
			setViewState(ViewState.Ok);

			scrollToPosition(
				listWrapperId,
				0,
				context.viewDataService.data.list.scrollPosition
			);
		}
	}, []);

	function getAlbums() {
		const albumsSub: Subscription = context.publicAlbumService
			.getAlbumPreviewsObs()
			.subscribe(
				(data: Album<Artwork>[]) => {
					setViewState(ViewState.Ok);

					setAlbums(data);

					if (!!data.length && data.length === albums.length) {
						setLoadMore(false);
					}
				},
				(error: any) => {
					console.warn(error);
					setViewState(ViewState.Error);
				},
				() => albumsSub.unsubscribe()
			);
	}

	return (
		<>
			{viewState === ViewState.Loading || viewState === ViewState.Error ? (
				<div style={cs.viewStateIndicator}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={
							viewState === ViewState.Loading ? Effect.Pulse : Effect.Static
						}>
						{viewState}
					</LogoIndicator>
				</div>
			) : (
				<div id={listWrapperId} style={cs.wrapper}>
					{getView(context.viewportType, albums)}
					{loadMore ? (
						<LoadMoreBar
							click={() => {
								getAlbums();
							}}
						/>
					) : null}
				</div>
			)}
		</>
	);
}
