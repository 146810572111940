import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	FlexDirection,
	FontStyle,
	FontWeight,
	JustifyContent,
	Position,
	FontFamily,
} from '../../../../../../ads-components/models';
import { defaultBackgroundColor, defaultTitle } from '../../../../../../styles';

const middleContainerViewAlbumButton = {
	cursor: Cursor.Pointer,
	marginBottom: '2vh',
};

const coverImageContainer = {
	position: Position.Relative,
	cursor: Cursor.Pointer,
};

export class ComponentStyle {
	wrapper: Wrapper;
	albumItemsContainer: AlbumItemsContainer;

	middleContainer = {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		flex: '1',
		padding: '4rem',
		//justifyContent: JustifyContent.SpaceEvenly,
		boxSizing: BoxSizing.BorderBox,
	};

	middleContainerInfoBar = {
		display: 'flex',
	};

	middleContainerTitle = {
		...defaultTitle,
		cursor: Cursor.Pointer,
		margin: '0.43rem 0 1.2rem 0',
		fontSize: '2.5rem',
		fontFamily: FontFamily.Lato,
		lineHeight: '3.2rem',
		fontWeight: FontWeight.Bolder,
		color: '#1E1F21',
	};

	middleContainerBottomBar = {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		justifyContent: JustifyContent.SpaceBetween,
		marginTop: '3rem',
	};

	middleTitleContentGroup = {
		overflow: 'auto',
	};

	middleContainerViewAlbumButton = middleContainerViewAlbumButton;

	forSaleTag = {
		position: Position.Absolute,
		margin: '1rem',
		color: '#565EE2',
	};

	coverImageContainer = coverImageContainer;

	noArtworksIndicator = {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		backgroundColor: defaultBackgroundColor,
		color: '#959699',
		fontStyle: FontStyle.Italic,
		width: '23%',
	};

	constructor(
		boxSideSize: string,
		albumItemsSpacing: string,
		albumItemsColumns: number,
		albumItemsRows: number
	) {
		this.wrapper = new Wrapper(boxSideSize);
		this.albumItemsContainer = new AlbumItemsContainer(
			albumItemsSpacing,
			albumItemsColumns,
			albumItemsRows
		);
	}
}

class AlbumItemsContainer {
	display = Display.Grid;
	padding: string;
	gridTemplateColumns: string;
	gridTemplateRows = 'repeat(3, 1fr)';
	gridGap: string;
	backgroundColor = defaultBackgroundColor;

	cursor = Cursor.Pointer;

	constructor(spacing: string, columns: number, rows: number) {
		this.padding = this.gridGap = spacing;
		this.gridTemplateColumns = `repeat(${columns}, 1fr)`;
		this.gridTemplateRows = `repeat(${rows}, 1fr)`;
	}
}

class Wrapper {
	height: string;

	display = Display.Flex;
	width = '100%';
	margin = '1px 0 4vh 0';
	boxShadow = `0 0 4px 0 rgba(0,0,0,0.15)`;
	boxSizing = BoxSizing.BorderBox;

	constructor(boxSideSize: string) {
		this.height = boxSideSize;
	}
}
