import {
	Display,
	FlexDirection,
	JustifyContent,
	AlignItems,
} from '../../../../ads-components/models';

export const componentStyle = {
	wrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		paddingBottom: '4vh',
		flex: '1',
	},
};
