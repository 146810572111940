import React from 'react';
import { componentStyle as cs } from './styles';
import { Props } from './models';
import { ArtworkCreation } from '../../../../../services/models/api-models';

export function ArtworkDetails(props: Props) {
	function getCreationString(creation: ArtworkCreation): string | null {
		if (
			!creation ||
			!creation.date ||
			!creation.date.start ||
			!creation.date.start.value
		) {
			return null;
		}

		const startYear = new Date(creation.date.start.value).getFullYear();
		const endYear = new Date(creation.date.end.value).getFullYear();

		return startYear !== endYear
			? `, ${startYear} - ${endYear}`
			: `, ${startYear}`;
	}

	return (
		<div style={cs.wrapper}>
			<div
				style={{
					fontWeight: 'bold',
					fontSize: '1.2rem',
					paddingBottom: '1.2vh',
				}}>
				{props.authorName}
			</div>
			<div
				style={{
					fontSize: '0.9rem',
					color: '#606162',
				}}>
				<i>{props.artworkTitle}</i> {getCreationString(props.creation)}
			</div>
		</div>
	);
}
