import {
	Display,
	FlexDirection,
	JustifyContent,
	BoxSizing,
	Cursor,
} from '../../../../../ads-components/models';

import { defaultBackgroundColor } from '../../../../../styles';

const defaultOutlineColor = '#0089D4';
const defaultOutlineOffset = '3px';

export const componentStyle = {
	mainContainer: {
		display: Display.Flex,
		flex: '1',
	},
	wrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		flex: '1 1 auto',
		height: '1px',
		padding: '0 7vw',
		outline: 'none',
	},
	navigationBar: {
		marginBottom: '4vh',
	},
	descriptionContainer: {
		marginBottom: '2vh',
	},
	imageContainer: {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		padding: '0 5vh 5vh 5vh',
		height: '100%',
	},
	leftContainer: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		boxSizing: BoxSizing.BorderBox,
		padding: '2vh',
		width: '50%',
		background: defaultBackgroundColor,
	},
	rightContainer: {
		boxSizing: BoxSizing.BorderBox,
		padding: '0 3vw',
		width: '50%',
		overflow: 'auto',
	},
	tabbedBoxContainer: {
		width: '70%',
		height: '70vh',
	},
	previewBox: function (isSelected: boolean) {
		let opacity = '1';
		let outline = 'none';
		let outlineOffset = '0';
		let cursor = Cursor.Pointer;

		if (!isSelected) {
			opacity = '.7';
		} else {
			outline = `${defaultOutlineOffset} solid ${defaultOutlineColor}`;
			outlineOffset = `-${defaultOutlineOffset}`;
			cursor = Cursor.Default;
		}

		return {
			marginRight: '0.5vw',
			opacity,
			outline,
			outlineOffset,
			cursor,
		};
	},
};
