import {
	Display,
	FlexDirection,
	JustifyContent,
	WebkitBoxOrient,
	AlignItems,
	BoxSizing,
} from '../../../../../ads-components/models';
import { ComponentStyle as ComponentStyleModel, HeaderStyle } from '../models';
import { defaultText, defaultTitle } from '../../../../../styles';

export class ComponentStyle implements ComponentStyleModel {
	header: HeaderStyle;

	constructor(headerContainerHeight: string) {
		this.header = {
			container: new Container(headerContainerHeight),
			wrapperContainer,
			leftContainer,
			leftTitle,
			leftDescription,
			leftForSaleAndPostDateContainer,
		};
	}
}

class Container {
	height: string;

	display = Display.Flex;
	justifyContent = JustifyContent.SpaceBetween;
	width = '100%';

	constructor(height: string) {
		this.height = height;
	}
}

const wrapperContainer = {
	margin: '0 4vw 12vh 4vw',
	overflow: 'hidden',
};

const leftContainer = {
	display: Display.Flex,
	flexDirection: FlexDirection.Column,
	boxSizing: BoxSizing.BorderBox,
	paddingBottom: '2vh',
	width: '40%',
};

const leftForSaleAndPostDateContainer = {
	display: Display.Flex,
	alignItems: AlignItems.Center,
	marginBottom: '2vh',
};

const leftTitle = {
	marginBottom: '4vh',
	WebkitLineClamp: '3',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitBoxOrient: WebkitBoxOrient.Vertical,
	...defaultTitle,
};

const leftDescription = {
	marginBottom: '2vh',
	...defaultText,
};
