import * as React from 'react';
import { Props } from './models';
import { componentStyle as cs } from './styles';

export function ArtworkDescriptionBar(props: Props) {
	return (
		<div style={cs.wrapper}>
			<div style={cs.artist}>{props.artist}</div>
			<div style={cs.title}>{props.title}</div>
			<div style={cs.inventoryNumber}>{props.inventoryNumber}</div>
		</div>
	);
}
