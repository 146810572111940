import { Album, Artwork } from '../services/models/api-models';

// NOTE: Album property served to views should not contain undefined entries for artworks (only cached album should)
export function getSanitizedAlbum(
	album: Album<Artwork | undefined>
): Album<Artwork> {
	const artworks = album.artworks.filter((artwork) => !!artwork) as Artwork[];

	return { ...album, artworks };
}
