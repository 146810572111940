import React, { useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { StaticArtworkSnapshotBox } from '../../../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import { ApiService } from '../../../../services/api.service';
import { ApiError } from '../../../../services/models/api-models';
import { decryptImage } from '../../../private/utlls';
import { missingArtworkIndicatorUrl } from '../../defaults';
import { Props } from './models';

export function EncryptedStaticArtworkSnapshotBox(props: Props) {
	const [imageSrc, setImageSrc] = useState<string>(
		'https://via.placeholder.com/600?text=Loading+image'
	);

	const componentSub = useRef(new Subscription());

	useEffect(() => {
		if (!props.imageSrc) {
			setImageSrc(missingArtworkIndicatorUrl);
		} else {
			componentSub.current.add(
				new ApiService()
					.getEncryptedAssetUrlById(props.imageSrc, 'small')
					.subscribe(
						(data: string | ApiError) => {
							const imageSrc = decryptImage(data as string, props.password);

							if (!imageSrc) {
								console.warn(
									'Unable to decrypt image',
									JSON.stringify(props.description)
								);
							} else {
								setImageSrc(imageSrc!);
							}
						},
						() => {
							console.warn('Image decryption error', props.imageSrc);
						}
					)
			);
		}

		return () => {
			componentSub.current &&
				(componentSub.current! as Subscription).unsubscribe();
		};
	}, [props.imageSrc]);

	return (
		<StaticArtworkSnapshotBox
			imageSrc={imageSrc}
			description={props.description}
		/>
	);
}
