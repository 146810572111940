import {
	AlignItems,
	Display,
	JustifyContent,
	Position,
	Cursor,
} from '../../../../../../ads-components/models';
import { ComponentStyleModel } from '../models';
import { defaultBackgroundColor } from '../../../../../../styles';

export class ComponentStyle implements ComponentStyleModel {
	container = {
		margin: '0 0 3rem 0',
	};

	header = {
		height: '',
		position: Position.Relative,
		display: Display.Flex,
		justifyContent: JustifyContent.SpaceBetween,
		cursor: Cursor.Pointer,
	};

	forSaleTag = {
		position: Position.Absolute,
		margin: '0.3rem',
		color: '#565EE2',
	};

	albumItems = {
		display: Display.Grid,
		gridGap: '',
		padding: '',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridTemplateRows: 'repeat(2, 1fr)',
		backgroundColor: defaultBackgroundColor,
		width: '100%',
	};

	content = {
		marginTop: '1rem',
	};

	infoBar = {
		color: 'gray',
	};

	iconsBar = {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		justifyContent: JustifyContent.SpaceBetween,
	};

	constructor(headerHeight: string, gridGap: string) {
		this.header.height = headerHeight;
		this.albumItems.gridGap = gridGap;
		this.albumItems.padding = gridGap;
	}
}
