import * as React from 'react';
import { Props, Status } from './models';
import { containerStyle as cs } from './styles/container.style';

export function IconStatusBar(props: Props) {
	const elements = props.statuses.map(
		(status: Status, index: number, arr: Status[]) => {
			return (
				<div key={index} style={{ display: 'flex', alignItems: 'center' }}>
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							marginRight: index < arr.length - 1 ? '1rem' : '',
							paddingRight: index < arr.length - 1 ? '1rem' : '',
							borderRight: index < arr.length - 1 ? '1px solid #E9EBE9' : '',
						}}>
						<img
							onClick={() => status.click && status.click()}
							src={status.iconUrl}
							alt="status"
							style={status.click ? cs.clickableImage : cs.image}
						/>

						<span style={{ fontSize: '1.3rem', color: '#5D5E5F' }}>
							{status.description}
						</span>
					</span>
				</div>
			);
		}
	);

	return <div style={cs.wrapper}>{elements}</div>;
}
