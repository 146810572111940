import { Cursor, TextAlign } from '../../../../ads-components/models';
import { defaultStyle } from '../../styles';

export const componentStyle = {
	...defaultStyle,
	browseText: {
		fonSize: '14px',
		lineHeight: '18px',
		textAlign: TextAlign.Center,
		color: '#050E0F',
		textDecoration: 'underline',
		cursor: Cursor.Pointer,
	},
};
