import React, { useState } from 'react';
import { BaseProps } from '../../../models';
import { componentStyle as baseComponentStyle } from '../../styles';

export function HoverableDefaultRoundBorderButton(props: BaseProps) {
	const [componentStyle, setComponentStyle] = useState(baseComponentStyle);

	const onHover = (action: 'enter' | 'leave') => {
		setComponentStyle({
			...baseComponentStyle,
			boxShadow: `rgb(207, 211, 212) 0px 0px ${
				action === 'enter' ? '10px' : '5px'
			} 0px`,
		});
	};

	return (
		<div
			style={componentStyle}
			onClick={() => props.onClick()}
			onMouseEnter={() => onHover('enter')}
			onMouseLeave={() => onHover('leave')}>
			{props.text}
		</div>
	);
}
