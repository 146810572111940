import { FontWeight, FontFamily, WhiteSpace, Cursor } from '../../../models';

export const componentStyle = {
	border: 'none',
	borderRadius: '4rem',
	fontSize: '1.3rem',
	lineHeight: '2rem',
	padding: '1rem 2.5rem',
	fontWeight: FontWeight.Bold,
	color: '#0089D4',
	cursor: Cursor.Pointer,
	boxShadow: 'rgb(207, 211, 212) 0px 0px 5px 0px',
	fontFamily: FontFamily.Lato,
	whiteSpace: WhiteSpace.Nowrap,
};
