import { ViewportType } from '../models';
import { ScreenOrientation } from '../../ads-components/models';
import { isSpecificDevice } from './is-specific-device';

const breakpoints = {
	portrait: {
		smallMax: 480,
		mediumMax: 1024,
	},
	landscape: {
		smallMax: 640,
		mediumMax: 1200,
	},
};

export function getViewportType(width: number, height: number): ViewportType {
	const orientation: ScreenOrientation =
		height / width > 1
			? ScreenOrientation.Portrait
			: ScreenOrientation.Landscape;

	// IPad Pro
	// NOTE: POC of adaptive font size base
	const specificDevice = isSpecificDevice(width, height);

	if (specificDevice) {
		return specificDevice;
	}

	// Portrait
	if (orientation === +ScreenOrientation.Portrait) {
		if (width <= breakpoints.portrait.smallMax) {
			return ViewportType.SmallPortrait;
		} else if (
			breakpoints.portrait.smallMax < width &&
			width <= breakpoints.portrait.mediumMax
		) {
			return ViewportType.MediumPortrait;
		}
	}

	// Landscape
	if (orientation === +ScreenOrientation.Landscape) {
		if (width <= breakpoints.landscape.smallMax) {
			return ViewportType.SmallLandscape;
		} else if (
			breakpoints.landscape.smallMax < width &&
			width <= breakpoints.landscape.mediumMax
		) {
			return ViewportType.MediumLandscape;
		}
	}

	return ViewportType.LargeLandscape;
}
