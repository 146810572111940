import { componentStyle as smallPortraitComponentStyle } from '../../small-portrait/styles';
import { BoxSizing } from '../../../../../ads-components/models';

export const componentStyle = {
	...smallPortraitComponentStyle,
	image: {
		...smallPortraitComponentStyle.image,
		height: '',
		padding: '3vh 20vw 0 20vw',
		boxSizing: BoxSizing.BorderBox,
	},
	navigationBar: {
		padding: '0 0 4vh 0',
	},
	previewList: {
		height: '8rem',
		boxSizing: BoxSizing.BorderBox,
		margin: '8vh 4vw 4vw 4vw',
	},
};
