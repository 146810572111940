import * as React from 'react';
import { Props } from '../models';
import { ComponentStyle } from './styles';

export function LogoToolbar(props: React.PropsWithChildren<Props>) {
	const cs = new ComponentStyle();

	return (
		<div style={cs.wrapper}>
			<img
				onClick={() => props.onLogoClick && props.onLogoClick()}
				src={props.imageUrl}
				alt="logo"
				style={cs.image}
			/>
			{props.children}
		</div>
	);
}
