import { Artwork } from '../../../services/models/api-models';

export function artworkContaineMeasurements(artwork: Artwork): boolean {
	// NOTE: Based on assumption that all checked properties are related
	// TODO: Update to default indicators providing missing data info instead of returning boolean?
	if (!artwork) {
		console.warn('Missing artwork. Unable to get measurements');

		return false;
	}

	const propList: any[] = [
		artwork.width,
		artwork.height,
		artwork.widthUnit,
		artwork.heightUnit,
		artwork.depthUnit,
	];

	return !!propList.filter((value) => !!value && !!Object.keys(value).length)
		.length;
}
