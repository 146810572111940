import { BackgroundRepeat } from '../../../models';

export class Style {
	backgroundImage: string;
	width: string;
	height: string;

	backgroundRepeat = BackgroundRepeat.NoRepeat;
	backgroundSize = 'cover';
	backgroundPosition = 'center center';

	constructor(backgroundImageUrl: string, sideSize: string) {
		this.backgroundImage = `url(${backgroundImageUrl})`;
		this.width = this.height = sideSize;
	}
}
