import {
	Display,
	FlexDirection,
	JustifyContent,
	AlignItems,
} from '../../../ads-components/models';

export const componentStyle = {
	wrapper: {
		padding: '0 8vw',
		overflow: 'auto',
		height: '100%',
	},
	viewStateIndicator: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		flex: '1',
	},
	noArtworks: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		flex: '1',
		height: '100%',
	},
};
