import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppContextProvider } from '../../context/AppContextProvider';
import { Details } from '../details/Details';
import { Home } from '../home/Home';
import { List } from '../list/List';
import { TopBar } from '../top-bar/TopBar';
import { PrivateLogin } from '../private/login/PrivateLogin';
import { ExpiredIndicator } from '../private/expired-indicator/ExpiredIndicator';
import { Login } from '../login/Login';

export default function () {
	return (
		<BrowserRouter>
			<React.Fragment>
				<AppContextProvider>
					<TopBar />

					<Switch>
						<Route exact path="/">
							<List />
						</Route>

						<Route path="/login">
							<Login />
						</Route>

						<Route path="/album">
							<Home />
						</Route>

						<Route path="*/details">
							<Details />
						</Route>

						<Route path="/private/login">
							<PrivateLogin />
						</Route>

						<Route path="/private/expired">
							<ExpiredIndicator />
						</Route>

						{
							// NOTE: Existing api redirects (from Collect) to .../private/albumuuid/artworks
						}
						<Route path="/private">
							<Home />
						</Route>
					</Switch>
				</AppContextProvider>
			</React.Fragment>
		</BrowserRouter>
	);
}
