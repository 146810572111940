import * as React from 'react';
import { Props, State, Measurement } from './models';
import { Style } from './styles';

export class StaticImageBox extends React.Component<Props, State> {
	containerRef = React.createRef<HTMLDivElement>();

	handleImage = () => {
		if (this.props.imageSrc) {
			const image = new Image();
			image.src = this.props.imageSrc as string;

			image.onload = () => {
				if (this.containerRef.current) {
					let height = `${this.containerRef.current!.offsetHeight}px`;
					let width = `${this.containerRef.current!.offsetWidth}px`;

					if (this.props.measurement === +Measurement.Width) {
						height = `${
							image.height /
							(image.width / this.containerRef.current!.offsetWidth)
						}px`;
						width = '100%';
					}

					if (this.props.measurement === +Measurement.Height) {
						height = '100%';
						width = `${
							image.width /
							(image.height / this.containerRef.current!.offsetHeight)
						}px`;
					}

					this.setState({
						imageUrl: this.props.imageSrc,
						height: height,
						width: width,
						containerWidth: this.containerRef.current.clientWidth,
					});

					this.containerRef.current.style.setProperty('opacity', '1');
				}
			};

			image.onerror = () => {
				let side = this.containerRef.current
					? this.props.measurement === Measurement.Width
						? `${this.containerRef.current!.offsetWidth}px`
						: `${this.containerRef.current!.offsetHeight}px`
					: '';

				this.setState({
					imageUrl: 'https://via.placeholder.com/600?text=Image+error',
					height: side,
					width: side,
					containerWidth: this.containerRef.current!.clientWidth,
				});
			};
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			imageUrl: 'https://via.placeholder.com/600?text=Loading+image',
			height: this.props.measurement === +Measurement.Height ? '100%' : '30vh',
			width: this.props.measurement === +Measurement.Width ? '100%' : '30vw',
			containerWidth: 0,
		};

		this.handleImage();
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			JSON.stringify(prevProps) !== JSON.stringify(this.props) ||
			JSON.stringify(prevState) !== JSON.stringify(this.state) ||
			// NOTE: Takes care of dynamic resize aka when user manualy changes viewport width.
			prevState.containerWidth !== this.containerRef.current?.clientWidth
		) {
			this.handleImage();
		}
	}

	render() {
		return (
			<div
				id={`static-image-box-${Math.random()}`}
				ref={this.containerRef}
				style={{
					...new Style(this.state.imageUrl),
					height: this.state.height,
					width: this.state.width,
				}}></div>
		);
	}
}
