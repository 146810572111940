import { Preview } from '../services/models/api-models/preview.model';
import { missingArtworkIndicatorUrl } from '../components/generic';

// NOTE: Each artwork 'snapshot' is based on a preview ex. album home page grid
//		 This should be base64 encoded string and we only need assetId-size as imageSrc
//		 Adding ex. -small (aka size) is manadatory and is related to back-end incosistency
export function getPrivateArtworkImageSrc(previews: Preview[]): string {
	if (!previews || !previews.length) {
		return '';
	}

	const preview = previews.find((preview) => preview.small);

	return preview && preview.small ? preview.small : '';
}
