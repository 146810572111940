import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	JustifyContent,
} from '../../../ads-components/models';

export const componentStyle = {
	wrapper: {
		padding: '3rem 12rem',
		width: '100%',
		boxSizing: BoxSizing.BorderBox,
	},
	actions: {
		width: '100%',
		display: Display.Flex,
		justifyContent: JustifyContent.SpaceBetween,
		alignItems: AlignItems.Baseline,
		marginLeft: '2vw',
		paddingLeft: '2vw',
	},
	navigationButton: {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		cursor: Cursor.Pointer,
	},
	arrow: {
		marginRight: '0.5rem',
	},
	text: {
		fontSize: '1.5rem',
	},
};
