import React from 'react';
import { withRouter } from 'react-router-dom';
import { MaxTextLinesBox } from '../../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { AppContext } from '../../../../../context/context';
import { AlbumViewModel, WithRouterProps } from '../../../../../models';
import { getExpirationDate } from '../../../../../utils';
import { PostIndicator } from '../../../../generic';
import { IconsStatusBarContainer } from '../../../../generic/icon-status-bar-container/IconStatusBarContainer';
import { goToArtworkDetails } from '../../../../home/utils';
import { goToAlbumPage } from '../../utils/go-to-album-page';
import { ForSaleTag } from '../components/for-sale-tag/ForSaleTag';
import { ComponentStyleModel } from './models';
import { ComponentStyle } from './styles';
import { getHeaderSpacing } from './utils';

const numberOfAlbumSnapshotColumns = 2;

type State = {
	componentStyle: ComponentStyleModel;
	albumSnapshotSize: string;
	headerContainerWidth: number;
};

interface Props extends AlbumViewModel, WithRouterProps {}

class ListItemSmallPortraitBase extends React.Component<Props, State> {
	static contextType = AppContext;

	containerRef = React.createRef<HTMLDivElement>();

	updateState = () => {
		const containerWidth = this.containerRef.current!.offsetWidth;

		const currentHeaderSpacing = getHeaderSpacing(this.context.viewportType);

		const albumSpacingCombined =
			(numberOfAlbumSnapshotColumns + 1) *
			((this.context.dimensions.height / 100) * currentHeaderSpacing);

		const albumSnapshotSize = `${
			(containerWidth / 2 - albumSpacingCombined) / numberOfAlbumSnapshotColumns
		}px`;

		this.setState({
			componentStyle: new ComponentStyle(
				`${containerWidth / 2}px`,
				currentHeaderSpacing + 'vh'
			),
			albumSnapshotSize,
			headerContainerWidth: containerWidth,
		});
	};

	componentDidMount() {
		this.updateState();
	}

	componentDidUpdate() {
		this.containerRef.current!.offsetWidth !==
			this.state.headerContainerWidth && this.updateState();
	}

	render() {
		if (!this.state || !this.state.componentStyle) {
			return <div ref={this.containerRef}></div>;
		}

		let previews = this.props.previews.map((preview, index) => {
			return (
				<div
					onClick={() =>
						goToArtworkDetails(
							this.props.history,
							this.props.uuid,
							preview.artworkId
						)
					}
					key={index}>
					<SquareImageBox
						imageSrc={preview.url}
						boxSideSize={this.state.albumSnapshotSize}></SquareImageBox>
				</div>
			);
		});

		previews.length = 4;

		return (
			<div
				id={`list-item-${this.props.id}`}
				key={this.props.id}
				ref={this.containerRef}
				style={this.state.componentStyle.container}>
				<div style={this.state.componentStyle.header}>
					{this.props.forSale ? (
						<div style={this.state.componentStyle.forSaleTag}>
							<ForSaleTag></ForSaleTag>
						</div>
					) : null}
					<div
						onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}>
						<SquareImageBox
							imageSrc={this.props.coverImageSrc}
							boxSideSize={
								this.state.componentStyle.header.height
							}></SquareImageBox>
					</div>

					<div style={this.state.componentStyle.albumItems}>{previews}</div>
				</div>

				<div style={this.state.componentStyle.content}>
					{this.props.postedOn ? (
						<div style={this.state.componentStyle.infoBar}>
							<PostIndicator postedOn={this.props.postedOn} />
						</div>
					) : null}

					<div
						onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}
						style={{
							margin: '2vh 0',
							fontSize: '2rem',
							fontWeight: 'bolder',
							lineHeight: '100%',
						}}>
						{this.props.title}
					</div>

					<div style={{ margin: '2vh 0' }}>
						<MaxTextLinesBox maxLinesCount={5} showReadMoreIndicator={false}>
							{this.props.description}
						</MaxTextLinesBox>
					</div>

					{this.props.expiresOn ? (
						<div
							style={{
								fontSize: '0.8rem',
								color: 'gray',
								marginBottom: '2vh',
							}}>
							<span>{getExpirationDate(this.props.expiresOn as string)}</span>
						</div>
					) : null}

					<div style={this.state.componentStyle.iconsBar}>
						<IconsStatusBarContainer
							albumUuid={this.props.uuid}
							likes={this.props.likes}
							timesVisited={this.props.timesVisited}
							artworksCount={this.props.artworksCount}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export const ListItemSmallPortrait = withRouter(ListItemSmallPortraitBase);
