import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { arteiaLogoSrc } from '../generic';
import { componentStyle as cs } from './styles';
import { ViewState } from '../../models';
import { Props } from './models';
import { AppContext } from '../../context/context';
import { ViewActionsPortal } from '../generic/view-actions-portal/ViewActionsPortal';

export function Login(props: Props) {
	const context = useContext(AppContext);

	const [login, setLogin] = useState('test@test.com');
	const [password, setPassword] = useState('12345678');
	const [viewState, setViewState] = useState(ViewState.Ok);

	let history = useHistory();

	function handleSubmit(email: string, password: string) {
		setViewState(ViewState.Loading);

		const subscription = context.authService.login(email, password).subscribe(
			(loggedIn) => {
				history.push(props.redirectTo || '/');

				subscription.unsubscribe();
			},
			(loggedIn) => {
				setViewState(ViewState.Error);

				subscription.unsubscribe();
			}
		);
	}

	return (
		<div style={cs.wrapper}>
			<img src={arteiaLogoSrc} />

			<div style={cs.infoBox}>
				<div style={cs.inputWrapper}>
					<div style={{ marginBottom: '1rem' }}>
						<div style={cs.inputLabel}>Login</div>

						<input
							style={cs.input}
							type="text"
							value={login}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit(login, password);
								}
							}}
							onChange={(event) => {
								setLogin(event.target.value);
							}}
						/>

						<div style={cs.inputLabel}>Password</div>

						<input
							style={cs.input}
							type="password"
							value={password}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit(login, password);
								}
							}}
							onChange={(event) => {
								setPassword(event.target.value);
							}}
						/>

						{viewState === ViewState.Error ? (
							<span style={cs.error}>
								Incorrect credentials. Please verify login and password.
							</span>
						) : null}
					</div>

					<div
						style={cs.submitButton}
						onClick={() => handleSubmit(login, password)}>
						<span style={{ marginRight: '0.5rem' }}>Log in</span>{' '}
						<img src="/icons/arrow.svg" />
					</div>
				</div>
			</div>
		</div>
	);
}
