import React from 'react';
import { updateURI } from '.';
import { SquareImageBox } from '../../../ads-components/boxes/square-image-box/SquareImageBox';
import { Preview } from '../../../services/models/api-models/preview.model';
import { getImageAssetUrl } from '../../../utils/get-image-asset-url';
import { EncryptedSquareImageBox } from '../../generic/encrypted/encrypted-square-image/EncryptedSquareImage';
import { Props, State } from '../models';

// NOTE: This seems to big to create new getPrivatePreviews
// TODO: Extract shared logic
export function getPreviews(
	this: React.Component<Props, State>,
	previewBoxStyle: (isSelected: boolean) => any,
	containerHeight: string
): JSX.Element[] | null {
	const click = (index: number) => {
		// NOTE: Unified for private/public
		this.props.changeArtworkFn('next', true, index);
	};

	const previews = this.props.artwork.previews;

	if (!previews || !previews.length) {
		return null;
	}

	return previews.map((preview: Preview, index: number) => {
		return (
			<div
				key={index + Math.random()}
				onClick={() => click(index)}
				onMouseEnter={(event) => {
					if (this.props.previewIndex !== index) {
						(event.currentTarget as HTMLDivElement).style.setProperty(
							'opacity',
							'1'
						);
					}
				}}
				onMouseLeave={(event) => {
					if (this.props.previewIndex !== index) {
						(event.currentTarget as HTMLDivElement).style.setProperty(
							'opacity',
							'.7'
						);
					}
				}}
				style={previewBoxStyle(this.props.previewIndex === index)}>
				{this.props.private ? (
					<EncryptedSquareImageBox
						imageSrc={preview.small}
						boxSideSize={containerHeight}
						password={this.props.password as string}
					/>
				) : (
					<SquareImageBox
						imageSrc={getImageAssetUrl(preview.small)}
						boxSideSize={containerHeight}
					/>
				)}
			</div>
		);
	});
}
