import { BackgroundRepeat } from '../../../models';

export class Style {
	backgroundImage: string;

	backgroundRepeat = BackgroundRepeat.NoRepeat;
	backgroundSize = 'cover';
	backgroundPosition = 'center center';

	constructor(backgroundImageUrl: string) {
		this.backgroundImage = `url(${backgroundImageUrl})`;
	}
}
