export function getDetailsUrlParams(search: string) {
	const urlParams = new URLSearchParams(window.location.search);

	const albumUuid = urlParams.get('uuid');
	const artworkId = urlParams.get('id');
	const previewIndex = urlParams.get('preview');

	return {
		albumUuid,
		artworkId,
		previewIndex,
	};
}
