import React, { PropsWithChildren } from 'react';
import { Props } from './models';
import { containerStyle } from './styles';

export function ColumnVerticalContainer(props: PropsWithChildren<Props>) {
	let columns: any[] = [];

	if (props.children) {
		columns = (props.children as JSX.Element[])
			.reduce(
				(acc: any[], child: JSX.Element, index: number) => {
					if (index < props.numberOfColumns) {
						acc[index] = [child];
					} else {
						acc[
							acc.reduce(
								(acc: any[] | undefined, column: any[], index: number) =>
									!acc || column.length < acc[0] ? [column.length, index] : acc,
								undefined
							)[1]
						].push(child);
					}
					return acc;
				},
				[...new Array(props.numberOfColumns)]
			)
			// NOTE: Replace map with filter if we want to go back to adaptive number of columns when number of snapshots is less then number of columns
			//.filter((column: Snapshot[]) => !!column);
			.map((column) => (column ? column : []));
	}

	return (
		<div style={containerStyle}>
			{columns.map((items: any[], index: number, array: any[]) => {
				const id = `${index + 1}-column`;

				return (
					<div
						key={id}
						style={{
							width: `${100 / props.numberOfColumns}%`,
							marginRight:
								index === array.length - 1 ? '' : props.columnSpacing,
						}}>
						{items}
					</div>
				);
			})}
		</div>
	);
}
