import {
	AlignItems,
	Display,
	FlexDirection,
	FontStyle,
	JustifyContent,
	TextAlign,
} from '../../../ads-components/models';
import { defaultText } from '../../../styles';

export const defaultStyle = {
	wrapper: {
		width: '100%',
		height: '100%',
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		backgroundColor: '#FDF6ED',
	},
	infoBox: {
		width: '20vw',
		maxWidth: '400px',
		backgroundColor: 'white',
		padding: '3rem',
		margin: '1.5rem 0',
		boxShadow: '0 0 24px 0 rgba(0,0,0,0.05)',
		...defaultText,
		borderRadius: '25px',
	},
	infoText: {
		fonSize: '18px',
		fontStyle: FontStyle.Italic,
		lineHeight: '24px',
		textAlign: TextAlign.Center,
		marginBottom: '1.5rem',
		color: '#010203',
	},
	copyrightText: {
		color: '#7F8283',
		fontSize: '12px',
		lineHeight: '16px',
	},
};
