import React from 'react';
import { Props } from './models';
import { componentStyle as cs } from './styles';

export function NavigationBar(props: Props) {
	return (
		<div style={cs.wrapper}>
			<img
				onClick={() => props.changeArtwork('previous')}
				style={cs.arrow(!!props.artworkIndex)}
				src="/icons/ico-arrow-left-big.svg"
			/>
			<span style={cs.numberDisplay}>
				<strong style={{ paddingRight: '.5rem', color: 'black' }}>
					{props.artworkIndex + 1}
				</strong>
				<span style={{ paddingRight: '.5rem' }}>/</span>
				<span style={{ color: '#5f5f5f' }}>{props.albumArtworkCount}</span>
			</span>
			<img
				onClick={() => props.changeArtwork('next')}
				style={cs.arrow(props.albumArtworkCount - 1 > props.artworkIndex)}
				src="/icons/ico-arrow-right-big.svg"
			/>
		</div>
	);
}
