import React from 'react';
import { withRouter } from 'react-router';
import { StaticArtworkSnapshotBox } from '../../../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import { MaxTextLinesBox } from '../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { ColumnVerticalContainer } from '../../../../ads-components/containers/grids/columns/ColumnVerticalContainer';
import { FullScreenModal } from '../../../../ads-components/modals/full-screen/FullScreenModal';
import { AppContext } from '../../../../context/context';
import { AlbumViewModel, WithRouterProps } from '../../../../models';
import {
	getArtworkImageSrc,
	getImageAssetUrl,
	getPrivateArtworkImageSrc,
	scrollToPosition,
} from '../../../../utils';
import { ExpirationIndicator, PostIndicator } from '../../../generic';
import { EncryptedSquareImageBox } from '../../../generic/encrypted/encrypted-square-image/EncryptedSquareImage';
import { EncryptedStaticArtworkSnapshotBox } from '../../../generic/encrypted/encrypted-static-artwork-snapshot-box/EncryptedStaticArtworkSnapshotBox';
import { IconsStatusBarContainer } from '../../../generic/icon-status-bar-container/IconStatusBarContainer';
import { LoadMoreBar } from '../../../generic/load-more-bar/LoadMoreBar';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { ForSaleTag } from '../../../list/list-item/views/components/for-sale-tag/ForSaleTag';
import { actionStyle } from '../../../top-bar/styles';
import { artworkPreviewClick } from '../../utils';
import { ArtworkListElement, ComponentStyle } from './styles';

const headerHeight = '70vh';
const descriptionMaxLinesCount = 3;
const cs = new ComponentStyle(headerHeight);
const homeWrapperId = 'home-wrapper';

type State = {
	showModal: boolean;
};

interface Props extends AlbumViewModel, WithRouterProps {}

class HomeLargeLandscapeBase extends React.Component<Props, State> {
	static contextType = AppContext;

	imageContainerRef = React.createRef<HTMLDivElement>();

	setScrollPosition = () => {
		this.context.viewDataService.setHomeScrollPosition(
			this.props.uuid,
			document.getElementById(homeWrapperId)!.scrollTop
		);

		console.log(this.context.viewDataService.data.home[this.props.uuid]);
	};

	getArtworks = () => {
		const password = this.context.privateAlbumService.getPassword();

		return this.props.artworks.map((artwork, index, array) => (
			<div
				className="hoverable-image-opacity"
				onClick={() => {
					this.setScrollPosition();

					artworkPreviewClick(
						!!password,
						this.props.history,
						this.props.uuid,
						artwork._id
					);
				}}
				key={index}
				style={new ArtworkListElement(index !== array.length - 1)}>
				{this.context.privateAlbumService.getPassword() ? (
					// NOTE: There is no way to get here if privateAlbumService context does not have password set
					<EncryptedStaticArtworkSnapshotBox
						imageSrc={getPrivateArtworkImageSrc(artwork.previews)}
						password={password}
						description={{
							artist: artwork.authors[0].name.full,
							title: artwork.titles[0].value,
						}}
					/>
				) : (
					<StaticArtworkSnapshotBox
						imageSrc={getArtworkImageSrc(artwork.previews, getImageAssetUrl)}
						description={{
							artist: artwork.authors[0].name.full,
							title: artwork.titles[0].value,
						}}
					/>
				)}
			</div>
		));
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			showModal: false,
		};
	}

	componentDidMount() {
		setTimeout(() =>
			scrollToPosition(
				homeWrapperId,
				0,
				this.context.viewDataService.data.home[this.props.uuid] || 0
			)
		);
	}

	render() {
		if (!this.state) {
			return null;
		}

		const password = this.context.privateAlbumService.getPassword();

		return (
			<div id={homeWrapperId} style={{ padding: '3rem 8vw', overflow: 'auto' }}>
				<FullScreenModal visible={this.state.showModal}>
					<div
						style={{
							padding: '10vh 12vw',
							display: 'flex',
							boxSizing: 'border-box',
							height: '100%',
						}}>
						<div style={{ width: '25vw' }}>
							{password ? (
								<EncryptedSquareImageBox
									password={password}
									imageSrc={this.props.coverImageSrc}
									boxSideSize="25vw"
								/>
							) : this.props.coverImageSrc ? (
								<SquareImageBox
									imageSrc={this.props.coverImageSrc}
									boxSideSize="25vw"
								/>
							) : null}
						</div>
						<div
							style={{
								width: '55vw',
								padding: '0 6vw',
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
							}}>
							<div
								style={{
									...cs.header.leftTitle,
									display: 'block',
								}}>
								{this.props.title}
							</div>

							<div
								style={{
									fontSize: cs.header.leftDescription.fontSize,
									lineHeight: cs.header.leftDescription.lineHeight,
								}}>
								{this.props.description}
							</div>
						</div>

						<div style={{ marginLeft: '5vw' }}>
							<img
								onClick={() =>
									this.setState({
										showModal: false,
									})
								}
								style={{ cursor: 'pointer' }}
								src="/icons/ico-close-big.svg"
								alt="close"
							/>
						</div>
					</div>
				</FullScreenModal>
				<div
					style={{
						display: !this.state.showModal ? 'block' : 'none',
						overflowY: 'visible',
					}}>
					<div id="header-wrapper" style={cs.header.wrapperContainer}>
						<div ref={this.imageContainerRef} style={cs.header.container}>
							<div id="header-left-container" style={cs.header.leftContainer}>
								<div style={{ flex: '1' }}></div>

								<div style={cs.header.leftForSaleAndPostDateContainer}>
									{this.props.forSale ? (
										<div style={{ marginRight: '.5rem' }}>
											<ForSaleTag></ForSaleTag>
										</div>
									) : null}

									<PostIndicator postedOn={this.props.postedOn}></PostIndicator>
								</div>

								<div style={cs.header.leftTitle}>{this.props.title}</div>

								<div style={cs.header.leftDescription}>
									<MaxTextLinesBox
										showReadMoreIndicator={true}
										readMoreClick={() =>
											this.setState({
												showModal: true,
											})
										}
										maxLinesCount={descriptionMaxLinesCount}>
										{this.props.description}
									</MaxTextLinesBox>
								</div>

								<div style={{ marginBottom: '3vh' }}>
									<ExpirationIndicator
										expiresOn={this.props.expiresOn}></ExpirationIndicator>
								</div>

								<IconsStatusBarContainer
									albumUuid={this.props.uuid}
									likes={this.props.likes}
									timesVisited={this.props.timesVisited}
									artworksCount={this.props.artworksCount}
								/>
							</div>

							{password ? (
								<EncryptedSquareImageBox
									password={password}
									imageSrc={this.props.coverImageSrc}
									boxSideSize={headerHeight}
								/>
							) : (
								<SquareImageBox
									imageSrc={this.props.coverImageSrc}
									boxSideSize={headerHeight}
								/>
							)}
						</div>
					</div>

					<ColumnVerticalContainer numberOfColumns={4} columnSpacing="1rem">
						{this.getArtworks()}
					</ColumnVerticalContainer>

					{this.props.artworksCount > this.props.artworks.length ? (
						<LoadMoreBar click={() => this.props.loadMoreArtworks!()} />
					) : null}
				</div>

				<ViewActionsPortal>
					<div
						onClick={() => {
							this.setScrollPosition();

							this.props.history.push('/');
						}}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-arrow-left-big.svg"
						/>
						<span style={actionStyle.text}>All showrooms</span>
					</div>
				</ViewActionsPortal>
			</div>
		);
	}
}

export const HomeLargeLandscape = withRouter(HomeLargeLandscapeBase);
