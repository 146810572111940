import { FontFamily } from '../ads-components/models';

const defaultTitle = {
	fontFamily: FontFamily.Merriweather,
	fontWeight: 300,
	fontSize: '5rem',
	lineHeight: '130%',
};

const defaultText = {
	fontFamily: FontFamily.Lato,
	fontWeight: 300,
	fontSize: '1.5rem',
	lineHeight: '2rem',
};

const defaultBackgroundColor = '#f9f9f9';

const defaultHorizontalMargin = '4vw';

const defaultVerticalMargin = '4vh';

const defaultLogoIndicatorSize = '8rem';

const labelColor = '#555657';

const mutedColor = '#959699';

export {
	defaultTitle,
	defaultText,
	defaultHorizontalMargin,
	defaultVerticalMargin,
	defaultBackgroundColor,
	defaultLogoIndicatorSize,
	labelColor,
	mutedColor,
};
