import React, {
	useContext,
	useEffect,
	useRef,
	useState,
	MutableRefObject,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { LogoIndicator } from '../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { Effect } from '../../ads-components/indicators/logo-loading-indicator/models';
import { AppContext } from '../../context/context';
import { ViewportType } from '../../context/models';
import { ViewState, ViewType } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { defaultLogoIndicatorSize } from '../../styles';
import { getViewType } from '../../utils';
import { Props } from './models';
import {
	artworkContaineMeasurements,
	getDetailsUrlParams,
	updateURI,
} from './utils';
import { DetailsLargeLandscape, DetailsSmallPortrait } from './views';
import { DetailsMediumLandscape } from './views/medium-landscape/DetailsMediumLandscape';
import { DetailsMediumPortrait } from './views/medium-portrait/DetailsMediumPortrait';
import { Error } from '../../services/models/error-models';

let viewProps: Props | null = null;

function getView(viewportType: ViewportType, props: Props) {
	if (viewportType === +ViewportType.SmallPortrait) {
		return <DetailsSmallPortrait {...props} />;
	} else if (
		viewportType === +ViewportType.SmallLandscape ||
		viewportType === +ViewportType.MediumLandscape
	) {
		return <DetailsMediumLandscape {...props} />;
	} else if (viewportType === +ViewportType.MediumPortrait) {
		return <DetailsMediumPortrait {...props} />;
	} else {
		return <DetailsLargeLandscape {...props} />;
	}
}

// NOTE: Only difference between public/private artwork detaisl is data source and image retreival flow.
export function Details() {
	// NOTE: Public/private based on pathname (first element should be 'private'). Because of an extended error handling logic there should be only one
	//		 details gateway for different details view types.
	const viewType: ViewType = getViewType(window.location);

	const context = useContext(AppContext);

	const password = context.privateAlbumService.getPassword();

	let history = useHistory();

	if (viewType === +ViewType.Private && !password) {
		console.warn('Private artwork details. Missing password.');

		const urlParams = new URLSearchParams(history.location.search);

		const albumUuid = urlParams.get('uuid');

		history.push(`/private/login?uuid=${albumUuid}`);
	}

	const getAlbumSub: MutableRefObject<Subscription | null> = useRef(null);
	const getArtworkSub: MutableRefObject<Subscription | null> = useRef(null);
	const getAlbumArtworksSub: MutableRefObject<Subscription | null> = useRef(
		null
	);

	// NOTE: At the moment if user gets into application from private artwork details link we will redirect him to private album page
	//		 and from there he will be able to log in
	const [viewState, setViewState] = useState<ViewState>(
		viewType === +ViewType.Private && !password
			? ViewState.Error
			: ViewState.Loading
	);

	const [view, setView] = useState<any>(null);

	const { albumUuid, artworkId, previewIndex } = getDetailsUrlParams(
		window.location.search
	);

	const [currentArtwork, setCurrentArtwork] = useState<{
		id: string | null;
		preview: number;
	}>({ id: artworkId, preview: 0 });

	function changeArtwork(
		direction: 'next' | 'previous',
		changePreview: boolean,
		// NOTE: Current artwork preview index before change
		previewIndex: number | null
	) {
		getAlbumSub.current && getAlbumSub.current.unsubscribe();

		const observable =
			viewType === +ViewType.Private
				? context.privateAlbumService.getAlbumObs(albumUuid!, true)
				: context.publicAlbumService.getAlbumObs(albumUuid!);

		getAlbumSub.current = observable.subscribe(
			(album) => {
				const currentArtwork = viewProps!.artwork;

				if (changePreview) {
					updateURI(history, currentArtwork._id, previewIndex!);

					setCurrentArtwork({
						id: currentArtwork._id,
						preview: previewIndex!,
					});

					return;
				}

				if (
					(currentArtwork.artworkOffset === 0 && direction === 'previous') ||
					(currentArtwork.artworkOffset === album.artworksCount - 1 &&
						direction === 'next')
				) {
					return;
				}

				const previousArtwork =
					album.artworks[currentArtwork.artworkOffset - 1];

				const nextArtwork = album.artworks[currentArtwork.artworkOffset + 1];

				if (direction === 'next') {
					// NOTE: If next is in cache we are safe
					if (nextArtwork) {
						updateURI(history, nextArtwork._id, 0);

						setCurrentArtwork({ id: nextArtwork._id, preview: 0 });
					} else {
						// NOTE: Upload next artwork
						getMissingArtwork('next', currentArtwork.artworkOffset);
					}
				} else if (direction === 'previous') {
					if (previousArtwork) {
						updateURI(history, previousArtwork._id, 0);

						setCurrentArtwork({ id: previousArtwork._id, preview: 0 });
					} else {
						getMissingArtwork('previous', currentArtwork.artworkOffset);
					}
				}
			},
			(error) => {
				console.warn(Error[error]);
				setViewState(ViewState.Error);
			}
		);
	}

	const getMissingArtwork = (
		direction: 'next' | 'previous',
		// NOTE: Current means before next artwork load.
		currentArtworkOffset: number
	) => {
		getAlbumArtworksSub.current && getAlbumArtworksSub.current.unsubscribe();

		const initialSkip =
			direction === 'next'
				? currentArtworkOffset + 1
				: currentArtworkOffset - 1;

		const topSentinelIndex = direction === 'next' ? null : currentArtworkOffset;

		const observable =
			viewType === +ViewType.Private
				? context.privateAlbumService.getAlbumArtworksObs
				: context.publicAlbumService.getAlbumArtworksObs;

		getAlbumArtworksSub.current = observable(
			albumUuid!,
			1,
			initialSkip,
			topSentinelIndex
		).subscribe((album) => {
			if (!album.artworks[initialSkip]) {
				console.warn(
					'Trying to access undefined  next album artwork. At this point it should be loaded.'
				);

				setViewState(ViewState.Error);

				return;
			}

			updateURI(history, album.artworks[initialSkip]!._id, 0);

			setCurrentArtwork({
				id: album.artworks[initialSkip]!._id,
				preview: 0,
			});
		});
	};

	const update = (
		albumUuid: string,
		artworkId: string,
		previewIndex: string
	) => {
		const observable =
			viewType === +ViewType.Public
				? context.publicAlbumService.getArtworkObs(albumUuid, artworkId)
				: context.privateAlbumService.getArtworkObs(albumUuid, artworkId);

		getArtworkSub.current && getArtworkSub.current.unsubscribe();

		getArtworkSub.current = observable.subscribe(
			(data: any) => {
				let album = data[0] as Album<Artwork>;
				let artwork = data[1] as Artwork;

				// NOTE: Checking for artwork data required to display proper measurement details.
				artworkContaineMeasurements(artwork);

				// NOTE: Preview param needs to be numeric
				if (isNaN(+previewIndex)) {
					console.warn('URI param preview should be numberic');
					setViewState(ViewState.Error);

					return;
				}

				// NOTE: An artwork can have no previews so just warn that there is none
				if (artwork.previews.length - 1 < +previewIndex) {
					console.warn('Given artwork does not contain provided preview.');
				}

				viewProps = {
					albumName: album.name,
					artwork,
					password,
					private: viewType === +ViewType.Private ? true : false,
					previewIndex: +previewIndex,
					albumArtworkCount: album.artworksCount,
					changeArtworkFn: changeArtwork,
					match: null,
					history,
					location: history.location,
				};

				setView(getView(context.viewportType, viewProps));

				setViewState(ViewState.Ok);
			},
			(error) => {
				console.warn('API error while fetching album or artwork data.', error);

				setViewState(ViewState.Error);
			}
		);
	};

	useEffect(() => {
		if (albumUuid && artworkId && previewIndex) {
			update(albumUuid, artworkId, previewIndex);
		} else {
			console.warn(
				'URI is missing some query params.',
				{ albumUuid },
				{ artworkId },
				{ previewIndex }
			);
			setViewState(ViewState.Error);
		}
	}, [currentArtwork]);

	// NOTE: artworkId is being persisted between views as query parameter so when changing views (ex. orientation from smallPortrait to meduimLandscape)
	// 		 we need to make sure that proper artwork is being displayed. Keep in mind that artwork toggling (< 1/3 >) changes only local state in conponent
	// 		 and Details.tsx does not persist that data.
	// TODO: It might be worthwhile to keep global state for details view here. Remember to keep query string updated because it's used for link sharing!
	useEffect(() => {
		if (viewProps) {
			setView(getView(context.viewportType, viewProps!));
		}
	}, [context]);

	return viewState === ViewState.Loading || viewState === ViewState.Error ? (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				flex: '1',
			}}>
			<LogoIndicator
				width={defaultLogoIndicatorSize}
				effect={viewState === ViewState.Loading ? Effect.Pulse : Effect.Static}>
				{viewState}
			</LogoIndicator>
		</div>
	) : (
		view
	);
}
