import React from 'react';
import { componentStyle as cs } from './styles';
import { arteiaLogoSrc } from '../../generic';
import { useHistory } from 'react-router-dom';

export function ExpiredIndicator() {
	const history = useHistory();

	return (
		<div style={cs.wrapper}>
			<img src={arteiaLogoSrc} />

			<div style={cs.infoBox}>
				<div style={cs.infoText}>
					The showroom you are looking for is not available anymore.
				</div>
				<div
					style={cs.browseText}
					onClick={() => {
						history.push('/');
					}}>
					Browse other public showrooms >
				</div>
			</div>
			<span style={cs.copyrightText}>@2020 Arteia - All rights reserved</span>
		</div>
	);
}
