import { Month } from './models';

export function getPostedDate(postedDate: string): string {
	if (isNaN(Date.parse(postedDate))) {
		return 'Unable to find date';
	}

	const date = new Date(postedDate as string);

	const now = new Date(Date.now());

	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const millisecondsPerHour = 1000 * 60 * 60;
	const millisecondsPerMinute = 1000 * 60;
	const millisecondsPerSecond = 1000;

	const nowUTC = Date.UTC(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		now.getHours(),
		now.getMinutes(),
		now.getSeconds()
	);
	const dateUTC = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds()
	);

	const differenceInDays = Math.abs(
		Math.floor((nowUTC - dateUTC) / millisecondsPerDay)
	);

	if (differenceInDays === 0) {
		const differenceInHours = Math.floor(
			(nowUTC - dateUTC) / millisecondsPerHour
		);

		if (!differenceInHours) {
			const differenceInMinutes = Math.floor(
				(nowUTC - dateUTC) / millisecondsPerMinute
			);

			if (!differenceInMinutes) {
				const differenceInSeconds = Math.floor(
					(nowUTC - dateUTC) / millisecondsPerSecond
				);

				return `Posted ${differenceInSeconds} ${
					differenceInSeconds === 1 ? 'second' : 'seconds'
				} ago`;
			} else {
				return `Posted ${differenceInMinutes} ${
					differenceInMinutes === 1 ? 'minute' : 'minutes'
				} ago`;
			}
		} else {
			return `Posted ${differenceInHours} ${
				differenceInHours === 1 ? 'hour' : 'hours'
			} ago`;
		}
	}

	if (differenceInDays < 7) {
		return `Posted ${differenceInDays} ${
			differenceInDays === 1 ? 'day' : 'days'
		} ago`;
	}

	if (differenceInDays / 7 > 1 && differenceInDays / 7 < 4) {
		return `Posted ${Math.floor(differenceInDays / 7)} ${
			Math.floor(differenceInDays / 7) === 1 ? 'week' : 'weeks'
		} ago`;
	}

	return `Posted on 
    ${new Date(dateUTC).getDate()} 
    ${Month[new Date(dateUTC).getMonth()]} 
    ${new Date(dateUTC).getFullYear()}`;
}
