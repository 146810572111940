import React from 'react';
import { BaseProps } from '../../models';
import { componentStyle } from '../styles';

export function DefaultRoundBorderButton(props: BaseProps) {
	return (
		<div style={componentStyle} onClick={() => props.onClick()}>
			{props.text}
		</div>
	);
}
