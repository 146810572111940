import { FontStyle } from '../../../../../../ads-components/models';

export const componentStyle = {
	wrapper: {
		fontSize: '0.9rem',
		marginBottom: '2vh',
	},
	missingMeasurementsIndicator: {
		fontStyle: FontStyle.Italic,
		color: 'gray',
	},
};
