// NOTE: We are using URI as SSoT for view state
export function updateURI(
	history: any,
	currentArtworkId: string,
	currentPreviewIndex: number
) {
	const urlParams = new URLSearchParams(history.location.search);

	const newUrl = `${history.location.pathname}?uuid=${urlParams.get(
		'uuid'
	)}&id=${currentArtworkId}&preview=${currentPreviewIndex}`;

	history.push(newUrl);
}
