import React from 'react';
import { Props } from './models/props.model';
import { Style } from './styles';
import { missingArtworkIndicatorUrl } from '../../../components/generic';

export class SquareImageBox extends React.Component<Props, Style> {
	imageErrored: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = new Style(
			'https://via.placeholder.com/600?text=Loading+image',
			props.boxSideSize
		);

		if (props.imageSrc) {
			const image = new Image();
			image.src = props.imageSrc as string;

			image.onload = () => {
				this.setState(new Style(props.imageSrc, props.boxSideSize));
			};

			image.onerror = () => {
				this.imageErrored = true;

				// TODO: Check is it really necessary?
				this.setState(
					new Style(
						'https://via.placeholder.com/600?text=Image+error',
						props.boxSideSize
					)
				);
			};
		} else {
			this.setState(new Style(missingArtworkIndicatorUrl, props.boxSideSize));
		}
	}

	componentDidUpdate(prevProps: Props, prevState: Style) {
		if (
			JSON.stringify(prevState) !== JSON.stringify(this.state) ||
			prevProps.boxSideSize !== this.props.boxSideSize
		) {
			this.setState(
				new Style(
					!this.imageErrored
						? this.props.imageSrc
						: 'https://via.placeholder.com/600?text=Image+error',
					this.props.boxSideSize
				)
			);
		}
	}

	render() {
		return <div style={this.state}></div>;
	}
}
