import React from 'react';
import { Measurement } from '../../../ads-components/boxes/static-image-box/models';
import { EncryptedStaticImageBox } from '../../generic/encrypted/encrypted-static-image-box/EncryptedStaticImageBox';

export function getPrivatePreview(
	imageUrl: string,
	measurement: Measurement,
	password: string
) {
	if (!password) {
		console.warn('Get private preview - missing password', imageUrl);

		return null;
	}

	return (
		<EncryptedStaticImageBox
			imageSrc={imageUrl}
			measurement={measurement}
			password={password}
		/>
	);
}
