import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { LogoToolbar } from '../../ads-components/bars/toolbars/logo-toolbar/LogoToolbar';
import { AppContext } from '../../context/context';
import { arteiaLogoSrc } from '../generic';
import { componentStyle as cs } from './styles';
const logoUrl = arteiaLogoSrc;

export function TopBar() {
	const context = useContext(AppContext);
	const history = useHistory();

	return (
		<div id="toolbar-wrapper" style={cs.wrapper}>
			<LogoToolbar onLogoClick={() => history.push('/')} imageUrl={logoUrl}>
				<div id="toolbar-actions" style={cs.actions}>
					{/* NOTE: Portal entry point */}
					<div id="top-bar-portal"></div>
					<span style={{ flex: '1' }}></span>
					{/* <AvatarMenuButton
						avatarImageUrl={
							'https://ca.slack-edge.com/T6302FKNW-U92AWQG3X-9e482086a676-512'
						}></AvatarMenuButton>{' '} */}

					{!context.authService.isAuthenticated() ? (
						<button onClick={() => history.push('login')}>Login</button>
					) : (
						<button onClick={() => context.authService.logout()}>Logout</button>
					)}
				</div>
			</LogoToolbar>
		</div>
	);
}
