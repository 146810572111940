import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	FlexDirection,
	FontWeight,
	JustifyContent,
	TextAlign,
} from '../../../../ads-components/models';
import { defaultStyle } from '../../styles';

export const componentStyle = {
	...defaultStyle,
	infoText: {
		...defaultStyle.infoText,
		lineHeight: '23px',
		fontSize: '18px',
		color: '#010304',
	},
	inputLabel: {
		fontSize: '14px',
		lineHeight: '14px',
		marginBottom: '0.2rem',
		color: '#4A4C4D',
	},
	inputWrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		width: '100%',
	},
	input: {
		width: '100%',
		height: '2.5rem',
		fontSize: '2.5rem',
		border: 'solid 2px #E0E0E3',
	},
	submitButton: {
		backgroundColor: '#39BBF2',
		color: 'white',
		fontSize: '16px',
		lineHeight: '21px',
		fontWeight: FontWeight.Bold,
		padding: '1rem',
		boxShadow: '0 0 5px 0 rgba(20,75,99,0.17)',
		boxSizing: BoxSizing.BorderBox,
		width: '133px',
		height: '50px',
		textAlign: TextAlign.Center,
		borderRadius: '5px',
		cursor: Cursor.Pointer,
	},
	error: {
		color: '#BD4158',
		fontSize: '13px',
		lineHeight: '16px',
	},
};
