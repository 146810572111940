import {
	Display,
	JustifyContent,
	Cursor,
} from '../../../../../../ads-components/models';

export const componentStyle = {
	wrapper: {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		width: '100%',
	},
	arrow: function (active: boolean) {
		return {
			cursor: active ? Cursor.Pointer : Cursor.Default,
			opacity: active ? '1' : '.4',
		};
	},
	numberDisplay: {
		fontSize: '1.1rem',
		padding: '0 1rem',
	},
};
