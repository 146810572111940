import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StaticArtworkSnapshotBox } from '../../../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import { MaxTextLinesBox } from '../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { ColumnVerticalContainer } from '../../../../ads-components/containers/grids/columns/ColumnVerticalContainer';
import { AppContext } from '../../../../context/context';
import { ViewportType } from '../../../../context/models';
import { AlbumViewModel } from '../../../../models';
import { defaultHorizontalMargin } from '../../../../styles';
import {
	getArtworkImageSrc,
	getImageAssetUrl,
	getPrivateArtworkImageSrc,
} from '../../../../utils';
import {
	ExpirationIndicator,
	IconsStatusBarContainer,
	PostIndicator,
} from '../../../generic';
import { EncryptedSquareImageBox } from '../../../generic/encrypted/encrypted-square-image/EncryptedSquareImage';
import { EncryptedStaticArtworkSnapshotBox } from '../../../generic/encrypted/encrypted-static-artwork-snapshot-box/EncryptedStaticArtworkSnapshotBox';
import { artworkPreviewClick } from '../../utils';
import { ComponentStyle } from './styles';
import { LoadMoreBar } from '../../../generic/load-more-bar/LoadMoreBar';
import { actionStyle } from '../../../top-bar/styles';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';

export function HomeSmallPortrait(props: AlbumViewModel) {
	const context = useContext(AppContext);
	const [activeTabId, setActiveTabId] = useState(0);

	let history = useHistory();

	const password = context.privateAlbumService.getPassword();

	const cs = new ComponentStyle(
		context.viewportType === +ViewportType.SmallPortrait ? '50vh' : '70vh'
	);

	const tabScrollPositions = useRef({ details: 0, artworks: 0 });

	const detailsElement = (
		<>
			<div id="header-image" style={cs.header}>
				{password ? (
					<EncryptedSquareImageBox
						password={password}
						imageSrc={props.coverImageSrc}
						boxSideSize={cs.header.width}
					/>
				) : (
					<SquareImageBox
						imageSrc={props.coverImageSrc}
						boxSideSize={cs.header.width}
					/>
				)}
			</div>

			<div style={cs.detailsContent}>
				<div style={cs.iconStatusBarContainer}>
					<IconsStatusBarContainer
						albumUuid={props.uuid}
						likes={props.likes}
						timesVisited={props.timesVisited}
						artworksCount={props.artworksCount}
					/>
				</div>

				<PostIndicator postedOn={props.postedOn} />

				<div style={cs.title}>{props.title}</div>

				<div id="description" style={cs.description}>
					<MaxTextLinesBox showReadMoreIndicator={false} maxLinesCount={1000}>
						{props.description}
					</MaxTextLinesBox>
				</div>

				<ExpirationIndicator expiresOn={props.expiresOn} />
			</div>
		</>
	);

	const artworkElements = props.artworks.map((artwork, index, array) => (
		<div
			onClick={() =>
				artworkPreviewClick(!!password, history, props.uuid, artwork._id)
			}
			key={index}
			style={{
				marginBottom: index === array.length - 1 ? '' : '2vh',
				cursor: 'pointer',
			}}>
			{password ? (
				// NOTE: There is no way to get here if privateAlbumService context does not have password set
				<EncryptedStaticArtworkSnapshotBox
					imageSrc={getPrivateArtworkImageSrc(artwork.previews)}
					password={password}
					description={{
						artist: artwork.authors[0].name.full,
						title: artwork.titles[0].value,
					}}
				/>
			) : (
				<StaticArtworkSnapshotBox
					imageSrc={getArtworkImageSrc(artwork.previews, getImageAssetUrl)}
					description={{
						artist: artwork.authors[0].name.full,
						title: artwork.titles[0].value,
					}}
				/>
			)}
		</div>
	));

	const listElement = (
		<div style={cs.artworkList}>
			<ColumnVerticalContainer
				numberOfColumns={2}
				columnSpacing={defaultHorizontalMargin}>
				{artworkElements}
			</ColumnVerticalContainer>
			{props.artworksCount > props.artworks.length ? (
				<LoadMoreBar click={() => props.loadMoreArtworks!()} />
			) : null}
		</div>
	);

	return (
		<div style={cs.container}>
			<div style={cs.tabsContainer}>
				<div
					onClick={(event) => {
						if (activeTabId !== 0) {
							tabScrollPositions.current = {
								details: tabScrollPositions.current.details,
								artworks: document.getElementById('content-container')!
									.scrollTop,
							};

							setTimeout(() => {
								document
									.getElementById('content-container')!
									.scroll(0, tabScrollPositions.current.details);
							});

							setActiveTabId(0);
						}
					}}
					style={cs.tab(activeTabId === 0)}>
					DETAILS
				</div>

				<div
					onClick={(event) => {
						if (activeTabId !== 1) {
							tabScrollPositions.current = {
								details: document.getElementById('content-container')!
									.scrollTop,
								artworks: tabScrollPositions.current.artworks,
							};

							setTimeout(() => {
								document
									.getElementById('content-container')!
									.scroll(0, tabScrollPositions.current.artworks);
							});

							setActiveTabId(1);
						}
					}}
					style={cs.tab(activeTabId === 1)}>
					ARTWORKS
				</div>
			</div>

			<div id="content-container" style={cs.contentContainer}>
				{!activeTabId ? detailsElement : listElement}
			</div>

			<ViewActionsPortal>
				<div
					onClick={() => history.push('/')}
					style={actionStyle.navigationButton}>
					<img style={actionStyle.arrow} src="/icons/ico-arrow-left-big.svg" />
					<span style={{ fontSize: 'smaller' }}>All showrooms</span>
				</div>
			</ViewActionsPortal>
		</div>
	);
}
