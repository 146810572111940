import { Display, AlignItems, Cursor } from '../../../ads-components/models';

export const actionStyle = {
	navigationButton: {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		cursor: Cursor.Pointer,
	},

	arrow: {
		marginRight: '0.5rem',
	},

	text: {
		fontSize: '1.5rem',
	},
};
