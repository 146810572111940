import * as React from 'react';
import { Props, State } from './models';
import { ComponentStyle } from './styles';

export class MaxTextLinesBox extends React.Component<
	React.PropsWithChildren<Props>,
	State
> {
	containerRef = React.createRef<HTMLDivElement>();
	initialHeightContainer = React.createRef<HTMLDivElement>();

	setReadMoreIndicator = () => {
		const create = (style: any) => {
			return (
				<p
					onClick={() => this.props.readMoreClick && this.props.readMoreClick()}
					onMouseEnter={() => this.setReadMoreIndicator()}
					onMouseOut={() => this.setReadMoreIndicator()}
					style={style}>
					Read more
				</p>
			);
		};

		let style = { cursor: 'pointer', textDecoration: 'underline' };

		if (this.state.readMoreIndicator) {
			style =
				this.state.readMoreIndicator.props.style.textDecoration === 'underline'
					? { cursor: 'pointer', textDecoration: 'none' }
					: style;

			this.setState({
				readMoreIndicator: create(style),
			});
		} else {
			const readMoreIndicator =
				this.containerRef.current!.offsetHeight <
				this.initialHeightContainer.current!.offsetHeight
					? create(style)
					: null;

			readMoreIndicator &&
				(this.containerRef.current!.querySelector(
					'#initial-height-container'
				) as HTMLDivElement).style.setProperty('display', 'none');

			this.setState({
				readMoreIndicator: readMoreIndicator,
			});
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			readMoreIndicator: null,
		};
	}

	componentDidMount() {
		this.setReadMoreIndicator();
	}

	render() {
		const cs = new ComponentStyle(this.props.maxLinesCount.toString());

		return (
			<div ref={this.containerRef} style={{ position: 'relative' }}>
				{/* NOTE: Used for initial measurement of div content */}
				<div
					id="initial-height-container"
					ref={this.initialHeightContainer}
					style={cs.initialHeightContainer}>
					{this.props.children}
				</div>

				<div style={cs.contentContainer}>{this.props.children}</div>

				{this.props.showReadMoreIndicator ? (
					<div style={{ width: 'max-content' }}>
						{this.state.readMoreIndicator}
					</div>
				) : null}
			</div>
		);
	}
}
