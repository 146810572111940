import React, { useContext } from 'react';
import { MaxTextLinesBox } from '../../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { HoverableDefaultRoundBorderButton } from '../../../../../ads-components/buttons/round-border-button/default/hoverable/HoverableDefaultRoundBorderButton';
import { AlbumViewModel } from '../../../../../models';
import { defaultText } from '../../../../../styles';
import { ExpirationIndicator, PostIndicator } from '../../../../generic';
import { IconsStatusBarContainer } from '../../../../generic/icon-status-bar-container/IconStatusBarContainer';
import { goToAlbumPage } from '../../utils/go-to-album-page';
import { ForSaleTag } from '../components/for-sale-tag/ForSaleTag';
import { ComponentStyle } from './styles';
import { goToArtworkDetails } from '../../../../home/utils';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../context/context';

const containerHeight = '55vh';
const highlightColor = '#f6f6f6';

const albumItemsContainer = {
	itemSpacing: '3vh',
	numberOfColumns: 2,
	numberOfRows: 3,
};

export function ListItemLargeLandscape(props: AlbumViewModel) {
	const context = useContext(AppContext);

	const cs = new ComponentStyle(
		containerHeight,
		albumItemsContainer.itemSpacing,
		albumItemsContainer.numberOfColumns,
		albumItemsContainer.numberOfRows
	);

	const history = useHistory();

	let albumItems = props.previews.map((preview, index) => {
		const heightRelative =
			(parseInt(containerHeight) -
				(albumItemsContainer.numberOfRows + 1) *
					parseInt(albumItemsContainer.itemSpacing)) /
			albumItemsContainer.numberOfRows;

		return (
			<div
				onClick={() => {
					setScrollPosition();

					goToArtworkDetails(history, props.uuid, preview.artworkId);
				}}
				key={index}>
				<SquareImageBox
					key={index}
					imageSrc={preview.url}
					boxSideSize={heightRelative + 'vh'}
				/>
			</div>
		);
	});

	normalizeAlbumPreviews(
		albumItems,
		albumItemsContainer.numberOfRows * albumItemsContainer.numberOfColumns
	);

	const setScrollPosition = () =>
		context.viewDataService.setListScrollPosition(
			document.getElementById('list-wrapper')!.scrollTop
		);

	return (
		<div id={`list-item-${props.id}`} style={cs.wrapper}>
			<div
				onMouseEnter={(event) =>
					(event.currentTarget as HTMLDivElement).style.setProperty(
						'opacity',
						'0.95'
					)
				}
				onMouseLeave={(event) =>
					(event.currentTarget as HTMLDivElement).style.setProperty(
						'opacity',
						'1'
					)
				}
				style={cs.coverImageContainer}
				onClick={() => {
					setScrollPosition();

					goToAlbumPage(history, props.uuid);
				}}>
				{props.forSale ? (
					<div style={cs.forSaleTag}>
						<ForSaleTag />
					</div>
				) : null}
				<SquareImageBox
					imageSrc={props.coverImageSrc}
					boxSideSize={containerHeight}
				/>
			</div>

			<div
				id={`list-item-${props.id}-middle-container`}
				style={cs.middleContainer}>
				<div style={cs.middleContainerInfoBar}>
					<PostIndicator postedOn={props.postedOn} />
				</div>

				<div style={cs.middleTitleContentGroup}>
					<div
						style={cs.middleContainerTitle}
						onClick={() => {
							setScrollPosition();

							goToAlbumPage(history, props.uuid);
						}}>
						{props.title}
					</div>

					<div style={{ ...defaultText, margin: '2vh 0' }}>
						<MaxTextLinesBox maxLinesCount={3} showReadMoreIndicator={false}>
							{props.description}
						</MaxTextLinesBox>
					</div>
				</div>

				<span style={{ flex: 1 }}></span>

				<ExpirationIndicator expiresOn={props.expiresOn} />

				<div id="bottom-bar" style={cs.middleContainerBottomBar}>
					<div style={{ margin: '0 1vw 2vh 0' }}>
						<IconsStatusBarContainer
							albumUuid={props.uuid}
							likes={props.likes}
							timesVisited={props.timesVisited}
							artworksCount={props.artworksCount}
						/>
					</div>

					<div
						style={cs.middleContainerViewAlbumButton}
						className="hoverable-button">
						<HoverableDefaultRoundBorderButton
							onClick={() => {
								setScrollPosition();
								goToAlbumPage(history, props.uuid);
							}}
							text="View album"></HoverableDefaultRoundBorderButton>
					</div>
				</div>
			</div>

			{albumItems.length ? (
				<div
					style={cs.albumItemsContainer}
					onMouseEnter={(event) =>
						(event.currentTarget as HTMLDivElement).style.setProperty(
							'background-color',
							highlightColor
						)
					}
					onMouseLeave={(event) =>
						(event.currentTarget as HTMLDivElement).style.setProperty(
							'background-color',
							cs.albumItemsContainer.backgroundColor
						)
					}>
					{albumItems}
				</div>
			) : (
				<div style={cs.noArtworksIndicator}>No artworks</div>
			)}
		</div>
	);
}

function normalizeAlbumPreviews(
	previews: JSX.Element[],
	maxPreviews: number
): JSX.Element[] {
	if (previews.length > maxPreviews) {
		previews.length = maxPreviews;

		return previews;
	}

	return previews;
}
