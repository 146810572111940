import React, { useContext, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { missingArtworkIndicatorUrl } from '../..';
import { StaticImageBox } from '../../../../ads-components/boxes/static-image-box/StaticImageBox';
import { AppContext } from '../../../../context/context';
import { ApiError } from '../../../../services/models/api-models';
import { decryptImage } from '../../../private/utlls';
import { Props } from './models';
import { ApiService } from '../../../../services/api.service';

export function EncryptedStaticImageBox(props: Props) {
	const context = useContext(AppContext);

	const [imageSrc, setImageSrc] = useState<string>(
		'https://via.placeholder.com/600?text=Loading+image'
	);

	const componentSub = useRef(new Subscription());
	const id = useRef(Math.random());

	useEffect(() => {
		if (!props.imageSrc) {
			setImageSrc(missingArtworkIndicatorUrl);
		} else {
			componentSub.current = new ApiService()
				.getEncryptedAssetUrlById(props.imageSrc, 'small')
				.subscribe(
					(data: string | ApiError) => {
						const imageSrc = decryptImage(data as string, props.password);

						if (!imageSrc) {
							console.warn('Unable to decrypt image');

							return;
						}

						setImageSrc(imageSrc);
					},
					() => {
						console.warn('Image decryption error', props.imageSrc);
					}
				);
		}

		return () => componentSub.current.unsubscribe();
	});

	return (
		<StaticImageBox
			key={id.current}
			imageSrc={imageSrc ? imageSrc : ''}
			measurement={props.measurement}
		/>
	);
}
